module.exports={
  "_args": [
    [
      {
        "raw": "yasgui-yasqe@2.11.7",
        "scope": null,
        "escapedName": "yasgui-yasqe",
        "name": "yasgui-yasqe",
        "rawSpec": "2.11.7",
        "spec": "2.11.7",
        "type": "version"
      },
      "/home/lrd900/yasgui/yasgui"
    ]
  ],
  "_from": "yasgui-yasqe@2.11.7",
  "_id": "yasgui-yasqe@2.11.7",
  "_inCache": true,
  "_location": "/yasgui-yasqe",
  "_nodeVersion": "7.4.0",
  "_npmOperationalInternal": {
    "host": "packages-12-west.internal.npmjs.com",
    "tmp": "tmp/yasgui-yasqe-2.11.7.tgz_1483956200361_0.051466147881001234"
  },
  "_npmUser": {
    "name": "laurens.rietveld",
    "email": "laurens.rietveld@gmail.com"
  },
  "_npmVersion": "4.0.5",
  "_phantomChildren": {},
  "_requested": {
    "raw": "yasgui-yasqe@2.11.7",
    "scope": null,
    "escapedName": "yasgui-yasqe",
    "name": "yasgui-yasqe",
    "rawSpec": "2.11.7",
    "spec": "2.11.7",
    "type": "version"
  },
  "_requiredBy": [
    "#USER",
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/yasgui-yasqe/-/yasgui-yasqe-2.11.7.tgz",
  "_shasum": "211073113c79e43c6a7d15d8cb6d9ffd2393cf5b",
  "_shrinkwrap": null,
  "_spec": "yasgui-yasqe@2.11.7",
  "_where": "/home/lrd900/yasgui/yasgui",
  "author": {
    "name": "Laurens Rietveld"
  },
  "bugs": {
    "url": "https://github.com/YASGUI/YASQE/issues/"
  },
  "dependencies": {
    "bootstrap-sass": "^3.3.7",
    "browserify": "^13.1.0",
    "browserify-shim": "^3.8.12",
    "browserify-transform-tools": "^1.6.0",
    "codemirror": "5.17.0",
    "exorcist": "^0.4.0",
    "gulp": "^3.9.1",
    "gulp-autoprefixer": "^3.1.0",
    "gulp-concat": "^2.6.0",
    "gulp-cssimport": "^3.1.0",
    "gulp-cssnano": "^2.1.2",
    "gulp-filter": "^4.0.0",
    "gulp-jsvalidate": "^2.1.0",
    "gulp-notify": "^2.2.0",
    "gulp-rename": "^1.2.2",
    "gulp-sass": "^2.3.2",
    "gulp-sourcemaps": "^1.6.0",
    "gulp-streamify": "1.0.2",
    "gulp-uglify": "^1.5.4",
    "jquery": "^2.2.4",
    "node-sass": "^3.8.0",
    "require-dir": "^0.3.0",
    "run-sequence": "^1.2.2",
    "vinyl-buffer": "^1.0.0",
    "vinyl-source-stream": "~1.1.0",
    "vinyl-transform": "1.0.0",
    "yasgui-utils": "^1.6.0"
  },
  "description": "Yet Another SPARQL Query Editor",
  "devDependencies": {
    "gulp-bump": "^2.2.0",
    "gulp-connect": "^4.2.0",
    "gulp-embedlr": "^0.5.2",
    "gulp-git": "^1.10.0",
    "gulp-livereload": "^3.8.1",
    "gulp-tag-version": "^1.3.0",
    "watchify": "^3.7.0"
  },
  "directories": {},
  "dist": {
    "shasum": "211073113c79e43c6a7d15d8cb6d9ffd2393cf5b",
    "tarball": "https://registry.npmjs.org/yasgui-yasqe/-/yasgui-yasqe-2.11.7.tgz"
  },
  "gitHead": "68a22f03933a16dbea8ab3a458c8c9fe194e6913",
  "homepage": "http://yasqe.yasgui.org",
  "keywords": [
    "JavaScript",
    "SPARQL",
    "Editor",
    "Semantic Web",
    "Linked Data"
  ],
  "license": "MIT",
  "main": "src/main.js",
  "maintainers": [
    {
      "name": "laurens.rietveld",
      "email": "laurens.rietveld@gmail.com"
    }
  ],
  "name": "yasgui-yasqe",
  "optionalDependencies": {},
  "optionalShim": {
    "codemirror": {
      "require": "codemirror",
      "global": "CodeMirror"
    },
    "jquery": {
      "require": "jquery",
      "global": "jQuery"
    },
    "../../lib/codemirror": {
      "require": "codemirror",
      "global": "CodeMirror"
    }
  },
  "readme": "ERROR: No README data found!",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/YASGUI/YASQE.git"
  },
  "scripts": {
    "build": "gulp",
    "dev": "gulp serve",
    "major": "gulp major",
    "minor": "gulp minor",
    "patch": "gulp patch"
  },
  "version": "2.11.7"
}
