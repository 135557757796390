module.exports = {table:
{
  "*[&&,valueLogical]" : {
     "&&": ["[&&,valueLogical]","*[&&,valueLogical]"], 
     "AS": [], 
     ")": [], 
     ",": [], 
     "||": [], 
     ";": []}, 
  "*[,,expression]" : {
     ",": ["[,,expression]","*[,,expression]"], 
     ")": []}, 
  "*[,,objectPath]" : {
     ",": ["[,,objectPath]","*[,,objectPath]"], 
     ".": [], 
     ";": [], 
     "]": [], 
     "{": [], 
     "OPTIONAL": [], 
     "MINUS": [], 
     "GRAPH": [], 
     "SERVICE": [], 
     "FILTER": [], 
     "BIND": [], 
     "VALUES": [], 
     "}": []}, 
  "*[,,object]" : {
     ",": ["[,,object]","*[,,object]"], 
     ".": [], 
     ";": [], 
     "]": [], 
     "}": [], 
     "GRAPH": [], 
     "{": [], 
     "OPTIONAL": [], 
     "MINUS": [], 
     "SERVICE": [], 
     "FILTER": [], 
     "BIND": [], 
     "VALUES": []}, 
  "*[/,pathEltOrInverse]" : {
     "/": ["[/,pathEltOrInverse]","*[/,pathEltOrInverse]"], 
     "|": [], 
     ")": [], 
     "(": [], 
     "[": [], 
     "VAR1": [], 
     "VAR2": [], 
     "NIL": [], 
     "IRI_REF": [], 
     "TRUE": [], 
     "FALSE": [], 
     "BLANK_NODE_LABEL": [], 
     "ANON": [], 
     "PNAME_LN": [], 
     "PNAME_NS": [], 
     "STRING_LITERAL1": [], 
     "STRING_LITERAL2": [], 
     "STRING_LITERAL_LONG1": [], 
     "STRING_LITERAL_LONG2": [], 
     "INTEGER": [], 
     "DECIMAL": [], 
     "DOUBLE": [], 
     "INTEGER_POSITIVE": [], 
     "DECIMAL_POSITIVE": [], 
     "DOUBLE_POSITIVE": [], 
     "INTEGER_NEGATIVE": [], 
     "DECIMAL_NEGATIVE": [], 
     "DOUBLE_NEGATIVE": []}, 
  "*[;,?[or([verbPath,verbSimple]),objectList]]" : {
     ";": ["[;,?[or([verbPath,verbSimple]),objectList]]","*[;,?[or([verbPath,verbSimple]),objectList]]"], 
     ".": [], 
     "]": [], 
     "{": [], 
     "OPTIONAL": [], 
     "MINUS": [], 
     "GRAPH": [], 
     "SERVICE": [], 
     "FILTER": [], 
     "BIND": [], 
     "VALUES": [], 
     "}": []}, 
  "*[;,?[verb,objectList]]" : {
     ";": ["[;,?[verb,objectList]]","*[;,?[verb,objectList]]"], 
     ".": [], 
     "]": [], 
     "}": [], 
     "GRAPH": [], 
     "{": [], 
     "OPTIONAL": [], 
     "MINUS": [], 
     "SERVICE": [], 
     "FILTER": [], 
     "BIND": [], 
     "VALUES": []}, 
  "*[UNION,groupGraphPattern]" : {
     "UNION": ["[UNION,groupGraphPattern]","*[UNION,groupGraphPattern]"], 
     "VAR1": [], 
     "VAR2": [], 
     "NIL": [], 
     "(": [], 
     "[": [], 
     "IRI_REF": [], 
     "TRUE": [], 
     "FALSE": [], 
     "BLANK_NODE_LABEL": [], 
     "ANON": [], 
     "PNAME_LN": [], 
     "PNAME_NS": [], 
     "STRING_LITERAL1": [], 
     "STRING_LITERAL2": [], 
     "STRING_LITERAL_LONG1": [], 
     "STRING_LITERAL_LONG2": [], 
     "INTEGER": [], 
     "DECIMAL": [], 
     "DOUBLE": [], 
     "INTEGER_POSITIVE": [], 
     "DECIMAL_POSITIVE": [], 
     "DOUBLE_POSITIVE": [], 
     "INTEGER_NEGATIVE": [], 
     "DECIMAL_NEGATIVE": [], 
     "DOUBLE_NEGATIVE": [], 
     ".": [], 
     "{": [], 
     "OPTIONAL": [], 
     "MINUS": [], 
     "GRAPH": [], 
     "SERVICE": [], 
     "FILTER": [], 
     "BIND": [], 
     "VALUES": [], 
     "}": []}, 
  "*[graphPatternNotTriples,?.,?triplesBlock]" : {
     "{": ["[graphPatternNotTriples,?.,?triplesBlock]","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "OPTIONAL": ["[graphPatternNotTriples,?.,?triplesBlock]","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "MINUS": ["[graphPatternNotTriples,?.,?triplesBlock]","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "GRAPH": ["[graphPatternNotTriples,?.,?triplesBlock]","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "SERVICE": ["[graphPatternNotTriples,?.,?triplesBlock]","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "FILTER": ["[graphPatternNotTriples,?.,?triplesBlock]","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "BIND": ["[graphPatternNotTriples,?.,?triplesBlock]","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "VALUES": ["[graphPatternNotTriples,?.,?triplesBlock]","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "}": []}, 
  "*[quadsNotTriples,?.,?triplesTemplate]" : {
     "GRAPH": ["[quadsNotTriples,?.,?triplesTemplate]","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "}": []}, 
  "*[|,pathOneInPropertySet]" : {
     "|": ["[|,pathOneInPropertySet]","*[|,pathOneInPropertySet]"], 
     ")": []}, 
  "*[|,pathSequence]" : {
     "|": ["[|,pathSequence]","*[|,pathSequence]"], 
     ")": [], 
     "(": [], 
     "[": [], 
     "VAR1": [], 
     "VAR2": [], 
     "NIL": [], 
     "IRI_REF": [], 
     "TRUE": [], 
     "FALSE": [], 
     "BLANK_NODE_LABEL": [], 
     "ANON": [], 
     "PNAME_LN": [], 
     "PNAME_NS": [], 
     "STRING_LITERAL1": [], 
     "STRING_LITERAL2": [], 
     "STRING_LITERAL_LONG1": [], 
     "STRING_LITERAL_LONG2": [], 
     "INTEGER": [], 
     "DECIMAL": [], 
     "DOUBLE": [], 
     "INTEGER_POSITIVE": [], 
     "DECIMAL_POSITIVE": [], 
     "DOUBLE_POSITIVE": [], 
     "INTEGER_NEGATIVE": [], 
     "DECIMAL_NEGATIVE": [], 
     "DOUBLE_NEGATIVE": []}, 
  "*[||,conditionalAndExpression]" : {
     "||": ["[||,conditionalAndExpression]","*[||,conditionalAndExpression]"], 
     "AS": [], 
     ")": [], 
     ",": [], 
     ";": []}, 
  "*dataBlockValue" : {
     "UNDEF": ["dataBlockValue","*dataBlockValue"], 
     "IRI_REF": ["dataBlockValue","*dataBlockValue"], 
     "TRUE": ["dataBlockValue","*dataBlockValue"], 
     "FALSE": ["dataBlockValue","*dataBlockValue"], 
     "PNAME_LN": ["dataBlockValue","*dataBlockValue"], 
     "PNAME_NS": ["dataBlockValue","*dataBlockValue"], 
     "STRING_LITERAL1": ["dataBlockValue","*dataBlockValue"], 
     "STRING_LITERAL2": ["dataBlockValue","*dataBlockValue"], 
     "STRING_LITERAL_LONG1": ["dataBlockValue","*dataBlockValue"], 
     "STRING_LITERAL_LONG2": ["dataBlockValue","*dataBlockValue"], 
     "INTEGER": ["dataBlockValue","*dataBlockValue"], 
     "DECIMAL": ["dataBlockValue","*dataBlockValue"], 
     "DOUBLE": ["dataBlockValue","*dataBlockValue"], 
     "INTEGER_POSITIVE": ["dataBlockValue","*dataBlockValue"], 
     "DECIMAL_POSITIVE": ["dataBlockValue","*dataBlockValue"], 
     "DOUBLE_POSITIVE": ["dataBlockValue","*dataBlockValue"], 
     "INTEGER_NEGATIVE": ["dataBlockValue","*dataBlockValue"], 
     "DECIMAL_NEGATIVE": ["dataBlockValue","*dataBlockValue"], 
     "DOUBLE_NEGATIVE": ["dataBlockValue","*dataBlockValue"], 
     "}": [], 
     ")": []}, 
  "*datasetClause" : {
     "FROM": ["datasetClause","*datasetClause"], 
     "WHERE": [], 
     "{": []}, 
  "*describeDatasetClause" : {
     "FROM": ["describeDatasetClause","*describeDatasetClause"], 
     "ORDER": [], 
     "HAVING": [], 
     "GROUP": [], 
     "LIMIT": [], 
     "OFFSET": [], 
     "WHERE": [], 
     "{": [], 
     "VALUES": [], 
     "$": []}, 
  "*graphNode" : {
     "(": ["graphNode","*graphNode"], 
     "[": ["graphNode","*graphNode"], 
     "VAR1": ["graphNode","*graphNode"], 
     "VAR2": ["graphNode","*graphNode"], 
     "NIL": ["graphNode","*graphNode"], 
     "IRI_REF": ["graphNode","*graphNode"], 
     "TRUE": ["graphNode","*graphNode"], 
     "FALSE": ["graphNode","*graphNode"], 
     "BLANK_NODE_LABEL": ["graphNode","*graphNode"], 
     "ANON": ["graphNode","*graphNode"], 
     "PNAME_LN": ["graphNode","*graphNode"], 
     "PNAME_NS": ["graphNode","*graphNode"], 
     "STRING_LITERAL1": ["graphNode","*graphNode"], 
     "STRING_LITERAL2": ["graphNode","*graphNode"], 
     "STRING_LITERAL_LONG1": ["graphNode","*graphNode"], 
     "STRING_LITERAL_LONG2": ["graphNode","*graphNode"], 
     "INTEGER": ["graphNode","*graphNode"], 
     "DECIMAL": ["graphNode","*graphNode"], 
     "DOUBLE": ["graphNode","*graphNode"], 
     "INTEGER_POSITIVE": ["graphNode","*graphNode"], 
     "DECIMAL_POSITIVE": ["graphNode","*graphNode"], 
     "DOUBLE_POSITIVE": ["graphNode","*graphNode"], 
     "INTEGER_NEGATIVE": ["graphNode","*graphNode"], 
     "DECIMAL_NEGATIVE": ["graphNode","*graphNode"], 
     "DOUBLE_NEGATIVE": ["graphNode","*graphNode"], 
     ")": []}, 
  "*graphNodePath" : {
     "(": ["graphNodePath","*graphNodePath"], 
     "[": ["graphNodePath","*graphNodePath"], 
     "VAR1": ["graphNodePath","*graphNodePath"], 
     "VAR2": ["graphNodePath","*graphNodePath"], 
     "NIL": ["graphNodePath","*graphNodePath"], 
     "IRI_REF": ["graphNodePath","*graphNodePath"], 
     "TRUE": ["graphNodePath","*graphNodePath"], 
     "FALSE": ["graphNodePath","*graphNodePath"], 
     "BLANK_NODE_LABEL": ["graphNodePath","*graphNodePath"], 
     "ANON": ["graphNodePath","*graphNodePath"], 
     "PNAME_LN": ["graphNodePath","*graphNodePath"], 
     "PNAME_NS": ["graphNodePath","*graphNodePath"], 
     "STRING_LITERAL1": ["graphNodePath","*graphNodePath"], 
     "STRING_LITERAL2": ["graphNodePath","*graphNodePath"], 
     "STRING_LITERAL_LONG1": ["graphNodePath","*graphNodePath"], 
     "STRING_LITERAL_LONG2": ["graphNodePath","*graphNodePath"], 
     "INTEGER": ["graphNodePath","*graphNodePath"], 
     "DECIMAL": ["graphNodePath","*graphNodePath"], 
     "DOUBLE": ["graphNodePath","*graphNodePath"], 
     "INTEGER_POSITIVE": ["graphNodePath","*graphNodePath"], 
     "DECIMAL_POSITIVE": ["graphNodePath","*graphNodePath"], 
     "DOUBLE_POSITIVE": ["graphNodePath","*graphNodePath"], 
     "INTEGER_NEGATIVE": ["graphNodePath","*graphNodePath"], 
     "DECIMAL_NEGATIVE": ["graphNodePath","*graphNodePath"], 
     "DOUBLE_NEGATIVE": ["graphNodePath","*graphNodePath"], 
     ")": []}, 
  "*groupCondition" : {
     "(": ["groupCondition","*groupCondition"], 
     "STR": ["groupCondition","*groupCondition"], 
     "LANG": ["groupCondition","*groupCondition"], 
     "LANGMATCHES": ["groupCondition","*groupCondition"], 
     "DATATYPE": ["groupCondition","*groupCondition"], 
     "BOUND": ["groupCondition","*groupCondition"], 
     "IRI": ["groupCondition","*groupCondition"], 
     "URI": ["groupCondition","*groupCondition"], 
     "BNODE": ["groupCondition","*groupCondition"], 
     "RAND": ["groupCondition","*groupCondition"], 
     "ABS": ["groupCondition","*groupCondition"], 
     "CEIL": ["groupCondition","*groupCondition"], 
     "FLOOR": ["groupCondition","*groupCondition"], 
     "ROUND": ["groupCondition","*groupCondition"], 
     "CONCAT": ["groupCondition","*groupCondition"], 
     "STRLEN": ["groupCondition","*groupCondition"], 
     "UCASE": ["groupCondition","*groupCondition"], 
     "LCASE": ["groupCondition","*groupCondition"], 
     "ENCODE_FOR_URI": ["groupCondition","*groupCondition"], 
     "CONTAINS": ["groupCondition","*groupCondition"], 
     "STRSTARTS": ["groupCondition","*groupCondition"], 
     "STRENDS": ["groupCondition","*groupCondition"], 
     "STRBEFORE": ["groupCondition","*groupCondition"], 
     "STRAFTER": ["groupCondition","*groupCondition"], 
     "YEAR": ["groupCondition","*groupCondition"], 
     "MONTH": ["groupCondition","*groupCondition"], 
     "DAY": ["groupCondition","*groupCondition"], 
     "HOURS": ["groupCondition","*groupCondition"], 
     "MINUTES": ["groupCondition","*groupCondition"], 
     "SECONDS": ["groupCondition","*groupCondition"], 
     "TIMEZONE": ["groupCondition","*groupCondition"], 
     "TZ": ["groupCondition","*groupCondition"], 
     "NOW": ["groupCondition","*groupCondition"], 
     "UUID": ["groupCondition","*groupCondition"], 
     "STRUUID": ["groupCondition","*groupCondition"], 
     "MD5": ["groupCondition","*groupCondition"], 
     "SHA1": ["groupCondition","*groupCondition"], 
     "SHA256": ["groupCondition","*groupCondition"], 
     "SHA384": ["groupCondition","*groupCondition"], 
     "SHA512": ["groupCondition","*groupCondition"], 
     "COALESCE": ["groupCondition","*groupCondition"], 
     "IF": ["groupCondition","*groupCondition"], 
     "STRLANG": ["groupCondition","*groupCondition"], 
     "STRDT": ["groupCondition","*groupCondition"], 
     "SAMETERM": ["groupCondition","*groupCondition"], 
     "ISIRI": ["groupCondition","*groupCondition"], 
     "ISURI": ["groupCondition","*groupCondition"], 
     "ISBLANK": ["groupCondition","*groupCondition"], 
     "ISLITERAL": ["groupCondition","*groupCondition"], 
     "ISNUMERIC": ["groupCondition","*groupCondition"], 
     "VAR1": ["groupCondition","*groupCondition"], 
     "VAR2": ["groupCondition","*groupCondition"], 
     "SUBSTR": ["groupCondition","*groupCondition"], 
     "REPLACE": ["groupCondition","*groupCondition"], 
     "REGEX": ["groupCondition","*groupCondition"], 
     "EXISTS": ["groupCondition","*groupCondition"], 
     "NOT": ["groupCondition","*groupCondition"], 
     "IRI_REF": ["groupCondition","*groupCondition"], 
     "PNAME_LN": ["groupCondition","*groupCondition"], 
     "PNAME_NS": ["groupCondition","*groupCondition"], 
     "VALUES": [], 
     "LIMIT": [], 
     "OFFSET": [], 
     "ORDER": [], 
     "HAVING": [], 
     "$": [], 
     "}": []}, 
  "*havingCondition" : {
     "(": ["havingCondition","*havingCondition"], 
     "STR": ["havingCondition","*havingCondition"], 
     "LANG": ["havingCondition","*havingCondition"], 
     "LANGMATCHES": ["havingCondition","*havingCondition"], 
     "DATATYPE": ["havingCondition","*havingCondition"], 
     "BOUND": ["havingCondition","*havingCondition"], 
     "IRI": ["havingCondition","*havingCondition"], 
     "URI": ["havingCondition","*havingCondition"], 
     "BNODE": ["havingCondition","*havingCondition"], 
     "RAND": ["havingCondition","*havingCondition"], 
     "ABS": ["havingCondition","*havingCondition"], 
     "CEIL": ["havingCondition","*havingCondition"], 
     "FLOOR": ["havingCondition","*havingCondition"], 
     "ROUND": ["havingCondition","*havingCondition"], 
     "CONCAT": ["havingCondition","*havingCondition"], 
     "STRLEN": ["havingCondition","*havingCondition"], 
     "UCASE": ["havingCondition","*havingCondition"], 
     "LCASE": ["havingCondition","*havingCondition"], 
     "ENCODE_FOR_URI": ["havingCondition","*havingCondition"], 
     "CONTAINS": ["havingCondition","*havingCondition"], 
     "STRSTARTS": ["havingCondition","*havingCondition"], 
     "STRENDS": ["havingCondition","*havingCondition"], 
     "STRBEFORE": ["havingCondition","*havingCondition"], 
     "STRAFTER": ["havingCondition","*havingCondition"], 
     "YEAR": ["havingCondition","*havingCondition"], 
     "MONTH": ["havingCondition","*havingCondition"], 
     "DAY": ["havingCondition","*havingCondition"], 
     "HOURS": ["havingCondition","*havingCondition"], 
     "MINUTES": ["havingCondition","*havingCondition"], 
     "SECONDS": ["havingCondition","*havingCondition"], 
     "TIMEZONE": ["havingCondition","*havingCondition"], 
     "TZ": ["havingCondition","*havingCondition"], 
     "NOW": ["havingCondition","*havingCondition"], 
     "UUID": ["havingCondition","*havingCondition"], 
     "STRUUID": ["havingCondition","*havingCondition"], 
     "MD5": ["havingCondition","*havingCondition"], 
     "SHA1": ["havingCondition","*havingCondition"], 
     "SHA256": ["havingCondition","*havingCondition"], 
     "SHA384": ["havingCondition","*havingCondition"], 
     "SHA512": ["havingCondition","*havingCondition"], 
     "COALESCE": ["havingCondition","*havingCondition"], 
     "IF": ["havingCondition","*havingCondition"], 
     "STRLANG": ["havingCondition","*havingCondition"], 
     "STRDT": ["havingCondition","*havingCondition"], 
     "SAMETERM": ["havingCondition","*havingCondition"], 
     "ISIRI": ["havingCondition","*havingCondition"], 
     "ISURI": ["havingCondition","*havingCondition"], 
     "ISBLANK": ["havingCondition","*havingCondition"], 
     "ISLITERAL": ["havingCondition","*havingCondition"], 
     "ISNUMERIC": ["havingCondition","*havingCondition"], 
     "SUBSTR": ["havingCondition","*havingCondition"], 
     "REPLACE": ["havingCondition","*havingCondition"], 
     "REGEX": ["havingCondition","*havingCondition"], 
     "EXISTS": ["havingCondition","*havingCondition"], 
     "NOT": ["havingCondition","*havingCondition"], 
     "IRI_REF": ["havingCondition","*havingCondition"], 
     "PNAME_LN": ["havingCondition","*havingCondition"], 
     "PNAME_NS": ["havingCondition","*havingCondition"], 
     "VALUES": [], 
     "LIMIT": [], 
     "OFFSET": [], 
     "ORDER": [], 
     "$": [], 
     "}": []}, 
  "*or([[ (,*dataBlockValue,)],NIL])" : {
     "(": ["or([[ (,*dataBlockValue,)],NIL])","*or([[ (,*dataBlockValue,)],NIL])"], 
     "NIL": ["or([[ (,*dataBlockValue,)],NIL])","*or([[ (,*dataBlockValue,)],NIL])"], 
     "}": []}, 
  "*or([[*,unaryExpression],[/,unaryExpression]])" : {
     "*": ["or([[*,unaryExpression],[/,unaryExpression]])","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "/": ["or([[*,unaryExpression],[/,unaryExpression]])","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "AS": [], 
     ")": [], 
     ",": [], 
     "||": [], 
     "&&": [], 
     "=": [], 
     "!=": [], 
     "<": [], 
     ">": [], 
     "<=": [], 
     ">=": [], 
     "IN": [], 
     "NOT": [], 
     "+": [], 
     "-": [], 
     "INTEGER_POSITIVE": [], 
     "DECIMAL_POSITIVE": [], 
     "DOUBLE_POSITIVE": [], 
     "INTEGER_NEGATIVE": [], 
     "DECIMAL_NEGATIVE": [], 
     "DOUBLE_NEGATIVE": [], 
     ";": []}, 
  "*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])" : {
     "+": ["or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "-": ["or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "INTEGER_POSITIVE": ["or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "DECIMAL_POSITIVE": ["or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "DOUBLE_POSITIVE": ["or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "INTEGER_NEGATIVE": ["or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "DECIMAL_NEGATIVE": ["or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "DOUBLE_NEGATIVE": ["or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "AS": [], 
     ")": [], 
     ",": [], 
     "||": [], 
     "&&": [], 
     "=": [], 
     "!=": [], 
     "<": [], 
     ">": [], 
     "<=": [], 
     ">=": [], 
     "IN": [], 
     "NOT": [], 
     ";": []}, 
  "*or([baseDecl,prefixDecl])" : {
     "BASE": ["or([baseDecl,prefixDecl])","*or([baseDecl,prefixDecl])"], 
     "PREFIX": ["or([baseDecl,prefixDecl])","*or([baseDecl,prefixDecl])"], 
     "$": [], 
     "CONSTRUCT": [], 
     "DESCRIBE": [], 
     "ASK": [], 
     "INSERT": [], 
     "DELETE": [], 
     "SELECT": [], 
     "LOAD": [], 
     "CLEAR": [], 
     "DROP": [], 
     "ADD": [], 
     "MOVE": [], 
     "COPY": [], 
     "CREATE": [], 
     "WITH": []}, 
  "*or([var,[ (,expression,AS,var,)]])" : {
     "(": ["or([var,[ (,expression,AS,var,)]])","*or([var,[ (,expression,AS,var,)]])"], 
     "VAR1": ["or([var,[ (,expression,AS,var,)]])","*or([var,[ (,expression,AS,var,)]])"], 
     "VAR2": ["or([var,[ (,expression,AS,var,)]])","*or([var,[ (,expression,AS,var,)]])"], 
     "WHERE": [], 
     "{": [], 
     "FROM": []}, 
  "*orderCondition" : {
     "ASC": ["orderCondition","*orderCondition"], 
     "DESC": ["orderCondition","*orderCondition"], 
     "VAR1": ["orderCondition","*orderCondition"], 
     "VAR2": ["orderCondition","*orderCondition"], 
     "(": ["orderCondition","*orderCondition"], 
     "STR": ["orderCondition","*orderCondition"], 
     "LANG": ["orderCondition","*orderCondition"], 
     "LANGMATCHES": ["orderCondition","*orderCondition"], 
     "DATATYPE": ["orderCondition","*orderCondition"], 
     "BOUND": ["orderCondition","*orderCondition"], 
     "IRI": ["orderCondition","*orderCondition"], 
     "URI": ["orderCondition","*orderCondition"], 
     "BNODE": ["orderCondition","*orderCondition"], 
     "RAND": ["orderCondition","*orderCondition"], 
     "ABS": ["orderCondition","*orderCondition"], 
     "CEIL": ["orderCondition","*orderCondition"], 
     "FLOOR": ["orderCondition","*orderCondition"], 
     "ROUND": ["orderCondition","*orderCondition"], 
     "CONCAT": ["orderCondition","*orderCondition"], 
     "STRLEN": ["orderCondition","*orderCondition"], 
     "UCASE": ["orderCondition","*orderCondition"], 
     "LCASE": ["orderCondition","*orderCondition"], 
     "ENCODE_FOR_URI": ["orderCondition","*orderCondition"], 
     "CONTAINS": ["orderCondition","*orderCondition"], 
     "STRSTARTS": ["orderCondition","*orderCondition"], 
     "STRENDS": ["orderCondition","*orderCondition"], 
     "STRBEFORE": ["orderCondition","*orderCondition"], 
     "STRAFTER": ["orderCondition","*orderCondition"], 
     "YEAR": ["orderCondition","*orderCondition"], 
     "MONTH": ["orderCondition","*orderCondition"], 
     "DAY": ["orderCondition","*orderCondition"], 
     "HOURS": ["orderCondition","*orderCondition"], 
     "MINUTES": ["orderCondition","*orderCondition"], 
     "SECONDS": ["orderCondition","*orderCondition"], 
     "TIMEZONE": ["orderCondition","*orderCondition"], 
     "TZ": ["orderCondition","*orderCondition"], 
     "NOW": ["orderCondition","*orderCondition"], 
     "UUID": ["orderCondition","*orderCondition"], 
     "STRUUID": ["orderCondition","*orderCondition"], 
     "MD5": ["orderCondition","*orderCondition"], 
     "SHA1": ["orderCondition","*orderCondition"], 
     "SHA256": ["orderCondition","*orderCondition"], 
     "SHA384": ["orderCondition","*orderCondition"], 
     "SHA512": ["orderCondition","*orderCondition"], 
     "COALESCE": ["orderCondition","*orderCondition"], 
     "IF": ["orderCondition","*orderCondition"], 
     "STRLANG": ["orderCondition","*orderCondition"], 
     "STRDT": ["orderCondition","*orderCondition"], 
     "SAMETERM": ["orderCondition","*orderCondition"], 
     "ISIRI": ["orderCondition","*orderCondition"], 
     "ISURI": ["orderCondition","*orderCondition"], 
     "ISBLANK": ["orderCondition","*orderCondition"], 
     "ISLITERAL": ["orderCondition","*orderCondition"], 
     "ISNUMERIC": ["orderCondition","*orderCondition"], 
     "SUBSTR": ["orderCondition","*orderCondition"], 
     "REPLACE": ["orderCondition","*orderCondition"], 
     "REGEX": ["orderCondition","*orderCondition"], 
     "EXISTS": ["orderCondition","*orderCondition"], 
     "NOT": ["orderCondition","*orderCondition"], 
     "IRI_REF": ["orderCondition","*orderCondition"], 
     "PNAME_LN": ["orderCondition","*orderCondition"], 
     "PNAME_NS": ["orderCondition","*orderCondition"], 
     "VALUES": [], 
     "LIMIT": [], 
     "OFFSET": [], 
     "$": [], 
     "}": []}, 
  "*usingClause" : {
     "USING": ["usingClause","*usingClause"], 
     "WHERE": []}, 
  "*var" : {
     "VAR1": ["var","*var"], 
     "VAR2": ["var","*var"], 
     ")": []}, 
  "*varOrIRIref" : {
     "VAR1": ["varOrIRIref","*varOrIRIref"], 
     "VAR2": ["varOrIRIref","*varOrIRIref"], 
     "IRI_REF": ["varOrIRIref","*varOrIRIref"], 
     "PNAME_LN": ["varOrIRIref","*varOrIRIref"], 
     "PNAME_NS": ["varOrIRIref","*varOrIRIref"], 
     "ORDER": [], 
     "HAVING": [], 
     "GROUP": [], 
     "LIMIT": [], 
     "OFFSET": [], 
     "WHERE": [], 
     "{": [], 
     "FROM": [], 
     "VALUES": [], 
     "$": []}, 
  "+graphNode" : {
     "(": ["graphNode","*graphNode"], 
     "[": ["graphNode","*graphNode"], 
     "VAR1": ["graphNode","*graphNode"], 
     "VAR2": ["graphNode","*graphNode"], 
     "NIL": ["graphNode","*graphNode"], 
     "IRI_REF": ["graphNode","*graphNode"], 
     "TRUE": ["graphNode","*graphNode"], 
     "FALSE": ["graphNode","*graphNode"], 
     "BLANK_NODE_LABEL": ["graphNode","*graphNode"], 
     "ANON": ["graphNode","*graphNode"], 
     "PNAME_LN": ["graphNode","*graphNode"], 
     "PNAME_NS": ["graphNode","*graphNode"], 
     "STRING_LITERAL1": ["graphNode","*graphNode"], 
     "STRING_LITERAL2": ["graphNode","*graphNode"], 
     "STRING_LITERAL_LONG1": ["graphNode","*graphNode"], 
     "STRING_LITERAL_LONG2": ["graphNode","*graphNode"], 
     "INTEGER": ["graphNode","*graphNode"], 
     "DECIMAL": ["graphNode","*graphNode"], 
     "DOUBLE": ["graphNode","*graphNode"], 
     "INTEGER_POSITIVE": ["graphNode","*graphNode"], 
     "DECIMAL_POSITIVE": ["graphNode","*graphNode"], 
     "DOUBLE_POSITIVE": ["graphNode","*graphNode"], 
     "INTEGER_NEGATIVE": ["graphNode","*graphNode"], 
     "DECIMAL_NEGATIVE": ["graphNode","*graphNode"], 
     "DOUBLE_NEGATIVE": ["graphNode","*graphNode"]}, 
  "+graphNodePath" : {
     "(": ["graphNodePath","*graphNodePath"], 
     "[": ["graphNodePath","*graphNodePath"], 
     "VAR1": ["graphNodePath","*graphNodePath"], 
     "VAR2": ["graphNodePath","*graphNodePath"], 
     "NIL": ["graphNodePath","*graphNodePath"], 
     "IRI_REF": ["graphNodePath","*graphNodePath"], 
     "TRUE": ["graphNodePath","*graphNodePath"], 
     "FALSE": ["graphNodePath","*graphNodePath"], 
     "BLANK_NODE_LABEL": ["graphNodePath","*graphNodePath"], 
     "ANON": ["graphNodePath","*graphNodePath"], 
     "PNAME_LN": ["graphNodePath","*graphNodePath"], 
     "PNAME_NS": ["graphNodePath","*graphNodePath"], 
     "STRING_LITERAL1": ["graphNodePath","*graphNodePath"], 
     "STRING_LITERAL2": ["graphNodePath","*graphNodePath"], 
     "STRING_LITERAL_LONG1": ["graphNodePath","*graphNodePath"], 
     "STRING_LITERAL_LONG2": ["graphNodePath","*graphNodePath"], 
     "INTEGER": ["graphNodePath","*graphNodePath"], 
     "DECIMAL": ["graphNodePath","*graphNodePath"], 
     "DOUBLE": ["graphNodePath","*graphNodePath"], 
     "INTEGER_POSITIVE": ["graphNodePath","*graphNodePath"], 
     "DECIMAL_POSITIVE": ["graphNodePath","*graphNodePath"], 
     "DOUBLE_POSITIVE": ["graphNodePath","*graphNodePath"], 
     "INTEGER_NEGATIVE": ["graphNodePath","*graphNodePath"], 
     "DECIMAL_NEGATIVE": ["graphNodePath","*graphNodePath"], 
     "DOUBLE_NEGATIVE": ["graphNodePath","*graphNodePath"]}, 
  "+groupCondition" : {
     "(": ["groupCondition","*groupCondition"], 
     "STR": ["groupCondition","*groupCondition"], 
     "LANG": ["groupCondition","*groupCondition"], 
     "LANGMATCHES": ["groupCondition","*groupCondition"], 
     "DATATYPE": ["groupCondition","*groupCondition"], 
     "BOUND": ["groupCondition","*groupCondition"], 
     "IRI": ["groupCondition","*groupCondition"], 
     "URI": ["groupCondition","*groupCondition"], 
     "BNODE": ["groupCondition","*groupCondition"], 
     "RAND": ["groupCondition","*groupCondition"], 
     "ABS": ["groupCondition","*groupCondition"], 
     "CEIL": ["groupCondition","*groupCondition"], 
     "FLOOR": ["groupCondition","*groupCondition"], 
     "ROUND": ["groupCondition","*groupCondition"], 
     "CONCAT": ["groupCondition","*groupCondition"], 
     "STRLEN": ["groupCondition","*groupCondition"], 
     "UCASE": ["groupCondition","*groupCondition"], 
     "LCASE": ["groupCondition","*groupCondition"], 
     "ENCODE_FOR_URI": ["groupCondition","*groupCondition"], 
     "CONTAINS": ["groupCondition","*groupCondition"], 
     "STRSTARTS": ["groupCondition","*groupCondition"], 
     "STRENDS": ["groupCondition","*groupCondition"], 
     "STRBEFORE": ["groupCondition","*groupCondition"], 
     "STRAFTER": ["groupCondition","*groupCondition"], 
     "YEAR": ["groupCondition","*groupCondition"], 
     "MONTH": ["groupCondition","*groupCondition"], 
     "DAY": ["groupCondition","*groupCondition"], 
     "HOURS": ["groupCondition","*groupCondition"], 
     "MINUTES": ["groupCondition","*groupCondition"], 
     "SECONDS": ["groupCondition","*groupCondition"], 
     "TIMEZONE": ["groupCondition","*groupCondition"], 
     "TZ": ["groupCondition","*groupCondition"], 
     "NOW": ["groupCondition","*groupCondition"], 
     "UUID": ["groupCondition","*groupCondition"], 
     "STRUUID": ["groupCondition","*groupCondition"], 
     "MD5": ["groupCondition","*groupCondition"], 
     "SHA1": ["groupCondition","*groupCondition"], 
     "SHA256": ["groupCondition","*groupCondition"], 
     "SHA384": ["groupCondition","*groupCondition"], 
     "SHA512": ["groupCondition","*groupCondition"], 
     "COALESCE": ["groupCondition","*groupCondition"], 
     "IF": ["groupCondition","*groupCondition"], 
     "STRLANG": ["groupCondition","*groupCondition"], 
     "STRDT": ["groupCondition","*groupCondition"], 
     "SAMETERM": ["groupCondition","*groupCondition"], 
     "ISIRI": ["groupCondition","*groupCondition"], 
     "ISURI": ["groupCondition","*groupCondition"], 
     "ISBLANK": ["groupCondition","*groupCondition"], 
     "ISLITERAL": ["groupCondition","*groupCondition"], 
     "ISNUMERIC": ["groupCondition","*groupCondition"], 
     "VAR1": ["groupCondition","*groupCondition"], 
     "VAR2": ["groupCondition","*groupCondition"], 
     "SUBSTR": ["groupCondition","*groupCondition"], 
     "REPLACE": ["groupCondition","*groupCondition"], 
     "REGEX": ["groupCondition","*groupCondition"], 
     "EXISTS": ["groupCondition","*groupCondition"], 
     "NOT": ["groupCondition","*groupCondition"], 
     "IRI_REF": ["groupCondition","*groupCondition"], 
     "PNAME_LN": ["groupCondition","*groupCondition"], 
     "PNAME_NS": ["groupCondition","*groupCondition"]}, 
  "+havingCondition" : {
     "(": ["havingCondition","*havingCondition"], 
     "STR": ["havingCondition","*havingCondition"], 
     "LANG": ["havingCondition","*havingCondition"], 
     "LANGMATCHES": ["havingCondition","*havingCondition"], 
     "DATATYPE": ["havingCondition","*havingCondition"], 
     "BOUND": ["havingCondition","*havingCondition"], 
     "IRI": ["havingCondition","*havingCondition"], 
     "URI": ["havingCondition","*havingCondition"], 
     "BNODE": ["havingCondition","*havingCondition"], 
     "RAND": ["havingCondition","*havingCondition"], 
     "ABS": ["havingCondition","*havingCondition"], 
     "CEIL": ["havingCondition","*havingCondition"], 
     "FLOOR": ["havingCondition","*havingCondition"], 
     "ROUND": ["havingCondition","*havingCondition"], 
     "CONCAT": ["havingCondition","*havingCondition"], 
     "STRLEN": ["havingCondition","*havingCondition"], 
     "UCASE": ["havingCondition","*havingCondition"], 
     "LCASE": ["havingCondition","*havingCondition"], 
     "ENCODE_FOR_URI": ["havingCondition","*havingCondition"], 
     "CONTAINS": ["havingCondition","*havingCondition"], 
     "STRSTARTS": ["havingCondition","*havingCondition"], 
     "STRENDS": ["havingCondition","*havingCondition"], 
     "STRBEFORE": ["havingCondition","*havingCondition"], 
     "STRAFTER": ["havingCondition","*havingCondition"], 
     "YEAR": ["havingCondition","*havingCondition"], 
     "MONTH": ["havingCondition","*havingCondition"], 
     "DAY": ["havingCondition","*havingCondition"], 
     "HOURS": ["havingCondition","*havingCondition"], 
     "MINUTES": ["havingCondition","*havingCondition"], 
     "SECONDS": ["havingCondition","*havingCondition"], 
     "TIMEZONE": ["havingCondition","*havingCondition"], 
     "TZ": ["havingCondition","*havingCondition"], 
     "NOW": ["havingCondition","*havingCondition"], 
     "UUID": ["havingCondition","*havingCondition"], 
     "STRUUID": ["havingCondition","*havingCondition"], 
     "MD5": ["havingCondition","*havingCondition"], 
     "SHA1": ["havingCondition","*havingCondition"], 
     "SHA256": ["havingCondition","*havingCondition"], 
     "SHA384": ["havingCondition","*havingCondition"], 
     "SHA512": ["havingCondition","*havingCondition"], 
     "COALESCE": ["havingCondition","*havingCondition"], 
     "IF": ["havingCondition","*havingCondition"], 
     "STRLANG": ["havingCondition","*havingCondition"], 
     "STRDT": ["havingCondition","*havingCondition"], 
     "SAMETERM": ["havingCondition","*havingCondition"], 
     "ISIRI": ["havingCondition","*havingCondition"], 
     "ISURI": ["havingCondition","*havingCondition"], 
     "ISBLANK": ["havingCondition","*havingCondition"], 
     "ISLITERAL": ["havingCondition","*havingCondition"], 
     "ISNUMERIC": ["havingCondition","*havingCondition"], 
     "SUBSTR": ["havingCondition","*havingCondition"], 
     "REPLACE": ["havingCondition","*havingCondition"], 
     "REGEX": ["havingCondition","*havingCondition"], 
     "EXISTS": ["havingCondition","*havingCondition"], 
     "NOT": ["havingCondition","*havingCondition"], 
     "IRI_REF": ["havingCondition","*havingCondition"], 
     "PNAME_LN": ["havingCondition","*havingCondition"], 
     "PNAME_NS": ["havingCondition","*havingCondition"]}, 
  "+or([var,[ (,expression,AS,var,)]])" : {
     "(": ["or([var,[ (,expression,AS,var,)]])","*or([var,[ (,expression,AS,var,)]])"], 
     "VAR1": ["or([var,[ (,expression,AS,var,)]])","*or([var,[ (,expression,AS,var,)]])"], 
     "VAR2": ["or([var,[ (,expression,AS,var,)]])","*or([var,[ (,expression,AS,var,)]])"]}, 
  "+orderCondition" : {
     "ASC": ["orderCondition","*orderCondition"], 
     "DESC": ["orderCondition","*orderCondition"], 
     "VAR1": ["orderCondition","*orderCondition"], 
     "VAR2": ["orderCondition","*orderCondition"], 
     "(": ["orderCondition","*orderCondition"], 
     "STR": ["orderCondition","*orderCondition"], 
     "LANG": ["orderCondition","*orderCondition"], 
     "LANGMATCHES": ["orderCondition","*orderCondition"], 
     "DATATYPE": ["orderCondition","*orderCondition"], 
     "BOUND": ["orderCondition","*orderCondition"], 
     "IRI": ["orderCondition","*orderCondition"], 
     "URI": ["orderCondition","*orderCondition"], 
     "BNODE": ["orderCondition","*orderCondition"], 
     "RAND": ["orderCondition","*orderCondition"], 
     "ABS": ["orderCondition","*orderCondition"], 
     "CEIL": ["orderCondition","*orderCondition"], 
     "FLOOR": ["orderCondition","*orderCondition"], 
     "ROUND": ["orderCondition","*orderCondition"], 
     "CONCAT": ["orderCondition","*orderCondition"], 
     "STRLEN": ["orderCondition","*orderCondition"], 
     "UCASE": ["orderCondition","*orderCondition"], 
     "LCASE": ["orderCondition","*orderCondition"], 
     "ENCODE_FOR_URI": ["orderCondition","*orderCondition"], 
     "CONTAINS": ["orderCondition","*orderCondition"], 
     "STRSTARTS": ["orderCondition","*orderCondition"], 
     "STRENDS": ["orderCondition","*orderCondition"], 
     "STRBEFORE": ["orderCondition","*orderCondition"], 
     "STRAFTER": ["orderCondition","*orderCondition"], 
     "YEAR": ["orderCondition","*orderCondition"], 
     "MONTH": ["orderCondition","*orderCondition"], 
     "DAY": ["orderCondition","*orderCondition"], 
     "HOURS": ["orderCondition","*orderCondition"], 
     "MINUTES": ["orderCondition","*orderCondition"], 
     "SECONDS": ["orderCondition","*orderCondition"], 
     "TIMEZONE": ["orderCondition","*orderCondition"], 
     "TZ": ["orderCondition","*orderCondition"], 
     "NOW": ["orderCondition","*orderCondition"], 
     "UUID": ["orderCondition","*orderCondition"], 
     "STRUUID": ["orderCondition","*orderCondition"], 
     "MD5": ["orderCondition","*orderCondition"], 
     "SHA1": ["orderCondition","*orderCondition"], 
     "SHA256": ["orderCondition","*orderCondition"], 
     "SHA384": ["orderCondition","*orderCondition"], 
     "SHA512": ["orderCondition","*orderCondition"], 
     "COALESCE": ["orderCondition","*orderCondition"], 
     "IF": ["orderCondition","*orderCondition"], 
     "STRLANG": ["orderCondition","*orderCondition"], 
     "STRDT": ["orderCondition","*orderCondition"], 
     "SAMETERM": ["orderCondition","*orderCondition"], 
     "ISIRI": ["orderCondition","*orderCondition"], 
     "ISURI": ["orderCondition","*orderCondition"], 
     "ISBLANK": ["orderCondition","*orderCondition"], 
     "ISLITERAL": ["orderCondition","*orderCondition"], 
     "ISNUMERIC": ["orderCondition","*orderCondition"], 
     "SUBSTR": ["orderCondition","*orderCondition"], 
     "REPLACE": ["orderCondition","*orderCondition"], 
     "REGEX": ["orderCondition","*orderCondition"], 
     "EXISTS": ["orderCondition","*orderCondition"], 
     "NOT": ["orderCondition","*orderCondition"], 
     "IRI_REF": ["orderCondition","*orderCondition"], 
     "PNAME_LN": ["orderCondition","*orderCondition"], 
     "PNAME_NS": ["orderCondition","*orderCondition"]}, 
  "+varOrIRIref" : {
     "VAR1": ["varOrIRIref","*varOrIRIref"], 
     "VAR2": ["varOrIRIref","*varOrIRIref"], 
     "IRI_REF": ["varOrIRIref","*varOrIRIref"], 
     "PNAME_LN": ["varOrIRIref","*varOrIRIref"], 
     "PNAME_NS": ["varOrIRIref","*varOrIRIref"]}, 
  "?." : {
     ".": ["."], 
     "VAR1": [], 
     "VAR2": [], 
     "NIL": [], 
     "(": [], 
     "[": [], 
     "IRI_REF": [], 
     "TRUE": [], 
     "FALSE": [], 
     "BLANK_NODE_LABEL": [], 
     "ANON": [], 
     "PNAME_LN": [], 
     "PNAME_NS": [], 
     "STRING_LITERAL1": [], 
     "STRING_LITERAL2": [], 
     "STRING_LITERAL_LONG1": [], 
     "STRING_LITERAL_LONG2": [], 
     "INTEGER": [], 
     "DECIMAL": [], 
     "DOUBLE": [], 
     "INTEGER_POSITIVE": [], 
     "DECIMAL_POSITIVE": [], 
     "DOUBLE_POSITIVE": [], 
     "INTEGER_NEGATIVE": [], 
     "DECIMAL_NEGATIVE": [], 
     "DOUBLE_NEGATIVE": [], 
     "GRAPH": [], 
     "{": [], 
     "OPTIONAL": [], 
     "MINUS": [], 
     "SERVICE": [], 
     "FILTER": [], 
     "BIND": [], 
     "VALUES": [], 
     "}": []}, 
  "?DISTINCT" : {
     "DISTINCT": ["DISTINCT"], 
     "!": [], 
     "+": [], 
     "-": [], 
     "VAR1": [], 
     "VAR2": [], 
     "(": [], 
     "STR": [], 
     "LANG": [], 
     "LANGMATCHES": [], 
     "DATATYPE": [], 
     "BOUND": [], 
     "IRI": [], 
     "URI": [], 
     "BNODE": [], 
     "RAND": [], 
     "ABS": [], 
     "CEIL": [], 
     "FLOOR": [], 
     "ROUND": [], 
     "CONCAT": [], 
     "STRLEN": [], 
     "UCASE": [], 
     "LCASE": [], 
     "ENCODE_FOR_URI": [], 
     "CONTAINS": [], 
     "STRSTARTS": [], 
     "STRENDS": [], 
     "STRBEFORE": [], 
     "STRAFTER": [], 
     "YEAR": [], 
     "MONTH": [], 
     "DAY": [], 
     "HOURS": [], 
     "MINUTES": [], 
     "SECONDS": [], 
     "TIMEZONE": [], 
     "TZ": [], 
     "NOW": [], 
     "UUID": [], 
     "STRUUID": [], 
     "MD5": [], 
     "SHA1": [], 
     "SHA256": [], 
     "SHA384": [], 
     "SHA512": [], 
     "COALESCE": [], 
     "IF": [], 
     "STRLANG": [], 
     "STRDT": [], 
     "SAMETERM": [], 
     "ISIRI": [], 
     "ISURI": [], 
     "ISBLANK": [], 
     "ISLITERAL": [], 
     "ISNUMERIC": [], 
     "TRUE": [], 
     "FALSE": [], 
     "COUNT": [], 
     "SUM": [], 
     "MIN": [], 
     "MAX": [], 
     "AVG": [], 
     "SAMPLE": [], 
     "GROUP_CONCAT": [], 
     "SUBSTR": [], 
     "REPLACE": [], 
     "REGEX": [], 
     "EXISTS": [], 
     "NOT": [], 
     "IRI_REF": [], 
     "STRING_LITERAL1": [], 
     "STRING_LITERAL2": [], 
     "STRING_LITERAL_LONG1": [], 
     "STRING_LITERAL_LONG2": [], 
     "INTEGER": [], 
     "DECIMAL": [], 
     "DOUBLE": [], 
     "INTEGER_POSITIVE": [], 
     "DECIMAL_POSITIVE": [], 
     "DOUBLE_POSITIVE": [], 
     "INTEGER_NEGATIVE": [], 
     "DECIMAL_NEGATIVE": [], 
     "DOUBLE_NEGATIVE": [], 
     "PNAME_LN": [], 
     "PNAME_NS": [], 
     "*": []}, 
  "?GRAPH" : {
     "GRAPH": ["GRAPH"], 
     "IRI_REF": [], 
     "PNAME_LN": [], 
     "PNAME_NS": []}, 
  "?SILENT" : {
     "SILENT": ["SILENT"], 
     "VAR1": [], 
     "VAR2": [], 
     "IRI_REF": [], 
     "PNAME_LN": [], 
     "PNAME_NS": []}, 
  "?SILENT_1" : {
     "SILENT": ["SILENT"], 
     "IRI_REF": [], 
     "PNAME_LN": [], 
     "PNAME_NS": []}, 
  "?SILENT_2" : {
     "SILENT": ["SILENT"], 
     "GRAPH": [], 
     "DEFAULT": [], 
     "NAMED": [], 
     "ALL": []}, 
  "?SILENT_3" : {
     "SILENT": ["SILENT"], 
     "GRAPH": []}, 
  "?SILENT_4" : {
     "SILENT": ["SILENT"], 
     "DEFAULT": [], 
     "GRAPH": [], 
     "IRI_REF": [], 
     "PNAME_LN": [], 
     "PNAME_NS": []}, 
  "?WHERE" : {
     "WHERE": ["WHERE"], 
     "{": []}, 
  "?[,,expression]" : {
     ",": ["[,,expression]"], 
     ")": []}, 
  "?[.,?constructTriples]" : {
     ".": ["[.,?constructTriples]"], 
     "}": []}, 
  "?[.,?triplesBlock]" : {
     ".": ["[.,?triplesBlock]"], 
     "{": [], 
     "OPTIONAL": [], 
     "MINUS": [], 
     "GRAPH": [], 
     "SERVICE": [], 
     "FILTER": [], 
     "BIND": [], 
     "VALUES": [], 
     "}": []}, 
  "?[.,?triplesTemplate]" : {
     ".": ["[.,?triplesTemplate]"], 
     "}": [], 
     "GRAPH": []}, 
  "?[;,SEPARATOR,=,string]" : {
     ";": ["[;,SEPARATOR,=,string]"], 
     ")": []}, 
  "?[;,update]" : {
     ";": ["[;,update]"], 
     "$": []}, 
  "?[AS,var]" : {
     "AS": ["[AS,var]"], 
     ")": []}, 
  "?[INTO,graphRef]" : {
     "INTO": ["[INTO,graphRef]"], 
     ";": [], 
     "$": []}, 
  "?[or([verbPath,verbSimple]),objectList]" : {
     "VAR1": ["[or([verbPath,verbSimple]),objectList]"], 
     "VAR2": ["[or([verbPath,verbSimple]),objectList]"], 
     "^": ["[or([verbPath,verbSimple]),objectList]"], 
     "a": ["[or([verbPath,verbSimple]),objectList]"], 
     "!": ["[or([verbPath,verbSimple]),objectList]"], 
     "(": ["[or([verbPath,verbSimple]),objectList]"], 
     "IRI_REF": ["[or([verbPath,verbSimple]),objectList]"], 
     "PNAME_LN": ["[or([verbPath,verbSimple]),objectList]"], 
     "PNAME_NS": ["[or([verbPath,verbSimple]),objectList]"], 
     ";": [], 
     ".": [], 
     "]": [], 
     "{": [], 
     "OPTIONAL": [], 
     "MINUS": [], 
     "GRAPH": [], 
     "SERVICE": [], 
     "FILTER": [], 
     "BIND": [], 
     "VALUES": [], 
     "}": []}, 
  "?[pathOneInPropertySet,*[|,pathOneInPropertySet]]" : {
     "a": ["[pathOneInPropertySet,*[|,pathOneInPropertySet]]"], 
     "^": ["[pathOneInPropertySet,*[|,pathOneInPropertySet]]"], 
     "IRI_REF": ["[pathOneInPropertySet,*[|,pathOneInPropertySet]]"], 
     "PNAME_LN": ["[pathOneInPropertySet,*[|,pathOneInPropertySet]]"], 
     "PNAME_NS": ["[pathOneInPropertySet,*[|,pathOneInPropertySet]]"], 
     ")": []}, 
  "?[update1,?[;,update]]" : {
     "INSERT": ["[update1,?[;,update]]"], 
     "DELETE": ["[update1,?[;,update]]"], 
     "LOAD": ["[update1,?[;,update]]"], 
     "CLEAR": ["[update1,?[;,update]]"], 
     "DROP": ["[update1,?[;,update]]"], 
     "ADD": ["[update1,?[;,update]]"], 
     "MOVE": ["[update1,?[;,update]]"], 
     "COPY": ["[update1,?[;,update]]"], 
     "CREATE": ["[update1,?[;,update]]"], 
     "WITH": ["[update1,?[;,update]]"], 
     "$": []}, 
  "?[verb,objectList]" : {
     "a": ["[verb,objectList]"], 
     "VAR1": ["[verb,objectList]"], 
     "VAR2": ["[verb,objectList]"], 
     "IRI_REF": ["[verb,objectList]"], 
     "PNAME_LN": ["[verb,objectList]"], 
     "PNAME_NS": ["[verb,objectList]"], 
     ";": [], 
     ".": [], 
     "]": [], 
     "}": [], 
     "GRAPH": [], 
     "{": [], 
     "OPTIONAL": [], 
     "MINUS": [], 
     "SERVICE": [], 
     "FILTER": [], 
     "BIND": [], 
     "VALUES": []}, 
  "?argList" : {
     "NIL": ["argList"], 
     "(": ["argList"], 
     "AS": [], 
     ")": [], 
     ",": [], 
     "||": [], 
     "&&": [], 
     "=": [], 
     "!=": [], 
     "<": [], 
     ">": [], 
     "<=": [], 
     ">=": [], 
     "IN": [], 
     "NOT": [], 
     "+": [], 
     "-": [], 
     "INTEGER_POSITIVE": [], 
     "DECIMAL_POSITIVE": [], 
     "DOUBLE_POSITIVE": [], 
     "INTEGER_NEGATIVE": [], 
     "DECIMAL_NEGATIVE": [], 
     "DOUBLE_NEGATIVE": [], 
     "*": [], 
     "/": [], 
     ";": []}, 
  "?constructTriples" : {
     "VAR1": ["constructTriples"], 
     "VAR2": ["constructTriples"], 
     "NIL": ["constructTriples"], 
     "(": ["constructTriples"], 
     "[": ["constructTriples"], 
     "IRI_REF": ["constructTriples"], 
     "TRUE": ["constructTriples"], 
     "FALSE": ["constructTriples"], 
     "BLANK_NODE_LABEL": ["constructTriples"], 
     "ANON": ["constructTriples"], 
     "PNAME_LN": ["constructTriples"], 
     "PNAME_NS": ["constructTriples"], 
     "STRING_LITERAL1": ["constructTriples"], 
     "STRING_LITERAL2": ["constructTriples"], 
     "STRING_LITERAL_LONG1": ["constructTriples"], 
     "STRING_LITERAL_LONG2": ["constructTriples"], 
     "INTEGER": ["constructTriples"], 
     "DECIMAL": ["constructTriples"], 
     "DOUBLE": ["constructTriples"], 
     "INTEGER_POSITIVE": ["constructTriples"], 
     "DECIMAL_POSITIVE": ["constructTriples"], 
     "DOUBLE_POSITIVE": ["constructTriples"], 
     "INTEGER_NEGATIVE": ["constructTriples"], 
     "DECIMAL_NEGATIVE": ["constructTriples"], 
     "DOUBLE_NEGATIVE": ["constructTriples"], 
     "}": []}, 
  "?groupClause" : {
     "GROUP": ["groupClause"], 
     "VALUES": [], 
     "LIMIT": [], 
     "OFFSET": [], 
     "ORDER": [], 
     "HAVING": [], 
     "$": [], 
     "}": []}, 
  "?havingClause" : {
     "HAVING": ["havingClause"], 
     "VALUES": [], 
     "LIMIT": [], 
     "OFFSET": [], 
     "ORDER": [], 
     "$": [], 
     "}": []}, 
  "?insertClause" : {
     "INSERT": ["insertClause"], 
     "WHERE": [], 
     "USING": []}, 
  "?limitClause" : {
     "LIMIT": ["limitClause"], 
     "VALUES": [], 
     "$": [], 
     "}": []}, 
  "?limitOffsetClauses" : {
     "LIMIT": ["limitOffsetClauses"], 
     "OFFSET": ["limitOffsetClauses"], 
     "VALUES": [], 
     "$": [], 
     "}": []}, 
  "?offsetClause" : {
     "OFFSET": ["offsetClause"], 
     "VALUES": [], 
     "$": [], 
     "}": []}, 
  "?or([DISTINCT,REDUCED])" : {
     "DISTINCT": ["or([DISTINCT,REDUCED])"], 
     "REDUCED": ["or([DISTINCT,REDUCED])"], 
     "*": [], 
     "(": [], 
     "VAR1": [], 
     "VAR2": []}, 
  "?or([LANGTAG,[^^,iriRef]])" : {
     "LANGTAG": ["or([LANGTAG,[^^,iriRef]])"], 
     "^^": ["or([LANGTAG,[^^,iriRef]])"], 
     "UNDEF": [], 
     "IRI_REF": [], 
     "TRUE": [], 
     "FALSE": [], 
     "PNAME_LN": [], 
     "PNAME_NS": [], 
     "STRING_LITERAL1": [], 
     "STRING_LITERAL2": [], 
     "STRING_LITERAL_LONG1": [], 
     "STRING_LITERAL_LONG2": [], 
     "INTEGER": [], 
     "DECIMAL": [], 
     "DOUBLE": [], 
     "INTEGER_POSITIVE": [], 
     "DECIMAL_POSITIVE": [], 
     "DOUBLE_POSITIVE": [], 
     "INTEGER_NEGATIVE": [], 
     "DECIMAL_NEGATIVE": [], 
     "DOUBLE_NEGATIVE": [], 
     "a": [], 
     "VAR1": [], 
     "VAR2": [], 
     "^": [], 
     "!": [], 
     "(": [], 
     ".": [], 
     ";": [], 
     ",": [], 
     "AS": [], 
     ")": [], 
     "||": [], 
     "&&": [], 
     "=": [], 
     "!=": [], 
     "<": [], 
     ">": [], 
     "<=": [], 
     ">=": [], 
     "IN": [], 
     "NOT": [], 
     "+": [], 
     "-": [], 
     "*": [], 
     "/": [], 
     "}": [], 
     "[": [], 
     "NIL": [], 
     "BLANK_NODE_LABEL": [], 
     "ANON": [], 
     "]": [], 
     "GRAPH": [], 
     "{": [], 
     "OPTIONAL": [], 
     "MINUS": [], 
     "SERVICE": [], 
     "FILTER": [], 
     "BIND": [], 
     "VALUES": []}, 
  "?or([[*,unaryExpression],[/,unaryExpression]])" : {
     "*": ["or([[*,unaryExpression],[/,unaryExpression]])"], 
     "/": ["or([[*,unaryExpression],[/,unaryExpression]])"], 
     "+": [], 
     "-": [], 
     "INTEGER_POSITIVE": [], 
     "DECIMAL_POSITIVE": [], 
     "DOUBLE_POSITIVE": [], 
     "INTEGER_NEGATIVE": [], 
     "DECIMAL_NEGATIVE": [], 
     "DOUBLE_NEGATIVE": [], 
     "AS": [], 
     ")": [], 
     ",": [], 
     "||": [], 
     "&&": [], 
     "=": [], 
     "!=": [], 
     "<": [], 
     ">": [], 
     "<=": [], 
     ">=": [], 
     "IN": [], 
     "NOT": [], 
     ";": []}, 
  "?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])" : {
     "=": ["or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "!=": ["or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "<": ["or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     ">": ["or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "<=": ["or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     ">=": ["or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "IN": ["or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "NOT": ["or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "AS": [], 
     ")": [], 
     ",": [], 
     "||": [], 
     "&&": [], 
     ";": []}, 
  "?orderClause" : {
     "ORDER": ["orderClause"], 
     "VALUES": [], 
     "LIMIT": [], 
     "OFFSET": [], 
     "$": [], 
     "}": []}, 
  "?pathMod" : {
     "*": ["pathMod"], 
     "?": ["pathMod"], 
     "+": ["pathMod"], 
     "{": ["pathMod"], 
     "|": [], 
     "/": [], 
     ")": [], 
     "(": [], 
     "[": [], 
     "VAR1": [], 
     "VAR2": [], 
     "NIL": [], 
     "IRI_REF": [], 
     "TRUE": [], 
     "FALSE": [], 
     "BLANK_NODE_LABEL": [], 
     "ANON": [], 
     "PNAME_LN": [], 
     "PNAME_NS": [], 
     "STRING_LITERAL1": [], 
     "STRING_LITERAL2": [], 
     "STRING_LITERAL_LONG1": [], 
     "STRING_LITERAL_LONG2": [], 
     "INTEGER": [], 
     "DECIMAL": [], 
     "DOUBLE": [], 
     "INTEGER_POSITIVE": [], 
     "DECIMAL_POSITIVE": [], 
     "DOUBLE_POSITIVE": [], 
     "INTEGER_NEGATIVE": [], 
     "DECIMAL_NEGATIVE": [], 
     "DOUBLE_NEGATIVE": []}, 
  "?triplesBlock" : {
     "VAR1": ["triplesBlock"], 
     "VAR2": ["triplesBlock"], 
     "NIL": ["triplesBlock"], 
     "(": ["triplesBlock"], 
     "[": ["triplesBlock"], 
     "IRI_REF": ["triplesBlock"], 
     "TRUE": ["triplesBlock"], 
     "FALSE": ["triplesBlock"], 
     "BLANK_NODE_LABEL": ["triplesBlock"], 
     "ANON": ["triplesBlock"], 
     "PNAME_LN": ["triplesBlock"], 
     "PNAME_NS": ["triplesBlock"], 
     "STRING_LITERAL1": ["triplesBlock"], 
     "STRING_LITERAL2": ["triplesBlock"], 
     "STRING_LITERAL_LONG1": ["triplesBlock"], 
     "STRING_LITERAL_LONG2": ["triplesBlock"], 
     "INTEGER": ["triplesBlock"], 
     "DECIMAL": ["triplesBlock"], 
     "DOUBLE": ["triplesBlock"], 
     "INTEGER_POSITIVE": ["triplesBlock"], 
     "DECIMAL_POSITIVE": ["triplesBlock"], 
     "DOUBLE_POSITIVE": ["triplesBlock"], 
     "INTEGER_NEGATIVE": ["triplesBlock"], 
     "DECIMAL_NEGATIVE": ["triplesBlock"], 
     "DOUBLE_NEGATIVE": ["triplesBlock"], 
     "{": [], 
     "OPTIONAL": [], 
     "MINUS": [], 
     "GRAPH": [], 
     "SERVICE": [], 
     "FILTER": [], 
     "BIND": [], 
     "VALUES": [], 
     "}": []}, 
  "?triplesTemplate" : {
     "VAR1": ["triplesTemplate"], 
     "VAR2": ["triplesTemplate"], 
     "NIL": ["triplesTemplate"], 
     "(": ["triplesTemplate"], 
     "[": ["triplesTemplate"], 
     "IRI_REF": ["triplesTemplate"], 
     "TRUE": ["triplesTemplate"], 
     "FALSE": ["triplesTemplate"], 
     "BLANK_NODE_LABEL": ["triplesTemplate"], 
     "ANON": ["triplesTemplate"], 
     "PNAME_LN": ["triplesTemplate"], 
     "PNAME_NS": ["triplesTemplate"], 
     "STRING_LITERAL1": ["triplesTemplate"], 
     "STRING_LITERAL2": ["triplesTemplate"], 
     "STRING_LITERAL_LONG1": ["triplesTemplate"], 
     "STRING_LITERAL_LONG2": ["triplesTemplate"], 
     "INTEGER": ["triplesTemplate"], 
     "DECIMAL": ["triplesTemplate"], 
     "DOUBLE": ["triplesTemplate"], 
     "INTEGER_POSITIVE": ["triplesTemplate"], 
     "DECIMAL_POSITIVE": ["triplesTemplate"], 
     "DOUBLE_POSITIVE": ["triplesTemplate"], 
     "INTEGER_NEGATIVE": ["triplesTemplate"], 
     "DECIMAL_NEGATIVE": ["triplesTemplate"], 
     "DOUBLE_NEGATIVE": ["triplesTemplate"], 
     "}": [], 
     "GRAPH": []}, 
  "?whereClause" : {
     "WHERE": ["whereClause"], 
     "{": ["whereClause"], 
     "ORDER": [], 
     "HAVING": [], 
     "GROUP": [], 
     "LIMIT": [], 
     "OFFSET": [], 
     "VALUES": [], 
     "$": []}, 
  "[ (,*dataBlockValue,)]" : {
     "(": ["(","*dataBlockValue",")"]}, 
  "[ (,*var,)]" : {
     "(": ["(","*var",")"]}, 
  "[ (,expression,)]" : {
     "(": ["(","expression",")"]}, 
  "[ (,expression,AS,var,)]" : {
     "(": ["(","expression","AS","var",")"]}, 
  "[!=,numericExpression]" : {
     "!=": ["!=","numericExpression"]}, 
  "[&&,valueLogical]" : {
     "&&": ["&&","valueLogical"]}, 
  "[*,unaryExpression]" : {
     "*": ["*","unaryExpression"]}, 
  "[*datasetClause,WHERE,{,?triplesTemplate,},solutionModifier]" : {
     "WHERE": ["*datasetClause","WHERE","{","?triplesTemplate","}","solutionModifier"], 
     "FROM": ["*datasetClause","WHERE","{","?triplesTemplate","}","solutionModifier"]}, 
  "[+,multiplicativeExpression]" : {
     "+": ["+","multiplicativeExpression"]}, 
  "[,,expression]" : {
     ",": [",","expression"]}, 
  "[,,integer,}]" : {
     ",": [",","integer","}"]}, 
  "[,,objectPath]" : {
     ",": [",","objectPath"]}, 
  "[,,object]" : {
     ",": [",","object"]}, 
  "[,,or([},[integer,}]])]" : {
     ",": [",","or([},[integer,}]])"]}, 
  "[-,multiplicativeExpression]" : {
     "-": ["-","multiplicativeExpression"]}, 
  "[.,?constructTriples]" : {
     ".": [".","?constructTriples"]}, 
  "[.,?triplesBlock]" : {
     ".": [".","?triplesBlock"]}, 
  "[.,?triplesTemplate]" : {
     ".": [".","?triplesTemplate"]}, 
  "[/,pathEltOrInverse]" : {
     "/": ["/","pathEltOrInverse"]}, 
  "[/,unaryExpression]" : {
     "/": ["/","unaryExpression"]}, 
  "[;,?[or([verbPath,verbSimple]),objectList]]" : {
     ";": [";","?[or([verbPath,verbSimple]),objectList]"]}, 
  "[;,?[verb,objectList]]" : {
     ";": [";","?[verb,objectList]"]}, 
  "[;,SEPARATOR,=,string]" : {
     ";": [";","SEPARATOR","=","string"]}, 
  "[;,update]" : {
     ";": [";","update"]}, 
  "[<,numericExpression]" : {
     "<": ["<","numericExpression"]}, 
  "[<=,numericExpression]" : {
     "<=": ["<=","numericExpression"]}, 
  "[=,numericExpression]" : {
     "=": ["=","numericExpression"]}, 
  "[>,numericExpression]" : {
     ">": [">","numericExpression"]}, 
  "[>=,numericExpression]" : {
     ">=": [">=","numericExpression"]}, 
  "[AS,var]" : {
     "AS": ["AS","var"]}, 
  "[IN,expressionList]" : {
     "IN": ["IN","expressionList"]}, 
  "[INTO,graphRef]" : {
     "INTO": ["INTO","graphRef"]}, 
  "[NAMED,iriRef]" : {
     "NAMED": ["NAMED","iriRef"]}, 
  "[NOT,IN,expressionList]" : {
     "NOT": ["NOT","IN","expressionList"]}, 
  "[UNION,groupGraphPattern]" : {
     "UNION": ["UNION","groupGraphPattern"]}, 
  "[^^,iriRef]" : {
     "^^": ["^^","iriRef"]}, 
  "[constructTemplate,*datasetClause,whereClause,solutionModifier]" : {
     "{": ["constructTemplate","*datasetClause","whereClause","solutionModifier"]}, 
  "[deleteClause,?insertClause]" : {
     "DELETE": ["deleteClause","?insertClause"]}, 
  "[graphPatternNotTriples,?.,?triplesBlock]" : {
     "{": ["graphPatternNotTriples","?.","?triplesBlock"], 
     "OPTIONAL": ["graphPatternNotTriples","?.","?triplesBlock"], 
     "MINUS": ["graphPatternNotTriples","?.","?triplesBlock"], 
     "GRAPH": ["graphPatternNotTriples","?.","?triplesBlock"], 
     "SERVICE": ["graphPatternNotTriples","?.","?triplesBlock"], 
     "FILTER": ["graphPatternNotTriples","?.","?triplesBlock"], 
     "BIND": ["graphPatternNotTriples","?.","?triplesBlock"], 
     "VALUES": ["graphPatternNotTriples","?.","?triplesBlock"]}, 
  "[integer,or([[,,or([},[integer,}]])],}])]" : {
     "INTEGER": ["integer","or([[,,or([},[integer,}]])],}])"]}, 
  "[integer,}]" : {
     "INTEGER": ["integer","}"]}, 
  "[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]" : {
     "INTEGER_POSITIVE": ["or([numericLiteralPositive,numericLiteralNegative])","?or([[*,unaryExpression],[/,unaryExpression]])"], 
     "DECIMAL_POSITIVE": ["or([numericLiteralPositive,numericLiteralNegative])","?or([[*,unaryExpression],[/,unaryExpression]])"], 
     "DOUBLE_POSITIVE": ["or([numericLiteralPositive,numericLiteralNegative])","?or([[*,unaryExpression],[/,unaryExpression]])"], 
     "INTEGER_NEGATIVE": ["or([numericLiteralPositive,numericLiteralNegative])","?or([[*,unaryExpression],[/,unaryExpression]])"], 
     "DECIMAL_NEGATIVE": ["or([numericLiteralPositive,numericLiteralNegative])","?or([[*,unaryExpression],[/,unaryExpression]])"], 
     "DOUBLE_NEGATIVE": ["or([numericLiteralPositive,numericLiteralNegative])","?or([[*,unaryExpression],[/,unaryExpression]])"]}, 
  "[or([verbPath,verbSimple]),objectList]" : {
     "VAR1": ["or([verbPath,verbSimple])","objectList"], 
     "VAR2": ["or([verbPath,verbSimple])","objectList"], 
     "^": ["or([verbPath,verbSimple])","objectList"], 
     "a": ["or([verbPath,verbSimple])","objectList"], 
     "!": ["or([verbPath,verbSimple])","objectList"], 
     "(": ["or([verbPath,verbSimple])","objectList"], 
     "IRI_REF": ["or([verbPath,verbSimple])","objectList"], 
     "PNAME_LN": ["or([verbPath,verbSimple])","objectList"], 
     "PNAME_NS": ["or([verbPath,verbSimple])","objectList"]}, 
  "[pathOneInPropertySet,*[|,pathOneInPropertySet]]" : {
     "a": ["pathOneInPropertySet","*[|,pathOneInPropertySet]"], 
     "^": ["pathOneInPropertySet","*[|,pathOneInPropertySet]"], 
     "IRI_REF": ["pathOneInPropertySet","*[|,pathOneInPropertySet]"], 
     "PNAME_LN": ["pathOneInPropertySet","*[|,pathOneInPropertySet]"], 
     "PNAME_NS": ["pathOneInPropertySet","*[|,pathOneInPropertySet]"]}, 
  "[quadsNotTriples,?.,?triplesTemplate]" : {
     "GRAPH": ["quadsNotTriples","?.","?triplesTemplate"]}, 
  "[update1,?[;,update]]" : {
     "INSERT": ["update1","?[;,update]"], 
     "DELETE": ["update1","?[;,update]"], 
     "LOAD": ["update1","?[;,update]"], 
     "CLEAR": ["update1","?[;,update]"], 
     "DROP": ["update1","?[;,update]"], 
     "ADD": ["update1","?[;,update]"], 
     "MOVE": ["update1","?[;,update]"], 
     "COPY": ["update1","?[;,update]"], 
     "CREATE": ["update1","?[;,update]"], 
     "WITH": ["update1","?[;,update]"]}, 
  "[verb,objectList]" : {
     "a": ["verb","objectList"], 
     "VAR1": ["verb","objectList"], 
     "VAR2": ["verb","objectList"], 
     "IRI_REF": ["verb","objectList"], 
     "PNAME_LN": ["verb","objectList"], 
     "PNAME_NS": ["verb","objectList"]}, 
  "[|,pathOneInPropertySet]" : {
     "|": ["|","pathOneInPropertySet"]}, 
  "[|,pathSequence]" : {
     "|": ["|","pathSequence"]}, 
  "[||,conditionalAndExpression]" : {
     "||": ["||","conditionalAndExpression"]}, 
  "add" : {
     "ADD": ["ADD","?SILENT_4","graphOrDefault","TO","graphOrDefault"]}, 
  "additiveExpression" : {
     "!": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "+": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "-": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "VAR1": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "VAR2": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "(": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "STR": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "LANG": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "LANGMATCHES": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "DATATYPE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "BOUND": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "IRI": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "URI": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "BNODE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "RAND": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "ABS": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "CEIL": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "FLOOR": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "ROUND": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "CONCAT": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "STRLEN": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "UCASE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "LCASE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "ENCODE_FOR_URI": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "CONTAINS": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "STRSTARTS": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "STRENDS": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "STRBEFORE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "STRAFTER": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "YEAR": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "MONTH": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "DAY": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "HOURS": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "MINUTES": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "SECONDS": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "TIMEZONE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "TZ": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "NOW": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "UUID": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "STRUUID": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "MD5": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "SHA1": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "SHA256": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "SHA384": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "SHA512": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "COALESCE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "IF": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "STRLANG": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "STRDT": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "SAMETERM": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "ISIRI": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "ISURI": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "ISBLANK": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "ISLITERAL": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "ISNUMERIC": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "TRUE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "FALSE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "COUNT": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "SUM": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "MIN": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "MAX": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "AVG": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "SAMPLE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "GROUP_CONCAT": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "SUBSTR": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "REPLACE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "REGEX": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "EXISTS": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "NOT": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "IRI_REF": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "STRING_LITERAL1": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "STRING_LITERAL2": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "STRING_LITERAL_LONG1": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "STRING_LITERAL_LONG2": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "INTEGER": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "DECIMAL": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "DOUBLE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "INTEGER_POSITIVE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "DECIMAL_POSITIVE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "DOUBLE_POSITIVE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "INTEGER_NEGATIVE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "DECIMAL_NEGATIVE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "DOUBLE_NEGATIVE": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "PNAME_LN": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"], 
     "PNAME_NS": ["multiplicativeExpression","*or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])"]}, 
  "aggregate" : {
     "COUNT": ["COUNT","(","?DISTINCT","or([*,expression])",")"], 
     "SUM": ["SUM","(","?DISTINCT","expression",")"], 
     "MIN": ["MIN","(","?DISTINCT","expression",")"], 
     "MAX": ["MAX","(","?DISTINCT","expression",")"], 
     "AVG": ["AVG","(","?DISTINCT","expression",")"], 
     "SAMPLE": ["SAMPLE","(","?DISTINCT","expression",")"], 
     "GROUP_CONCAT": ["GROUP_CONCAT","(","?DISTINCT","expression","?[;,SEPARATOR,=,string]",")"]}, 
  "allowBnodes" : {
     "}": []}, 
  "allowVars" : {
     "}": []}, 
  "argList" : {
     "NIL": ["NIL"], 
     "(": ["(","?DISTINCT","expression","*[,,expression]",")"]}, 
  "askQuery" : {
     "ASK": ["ASK","*datasetClause","whereClause","solutionModifier"]}, 
  "baseDecl" : {
     "BASE": ["BASE","IRI_REF"]}, 
  "bind" : {
     "BIND": ["BIND","(","expression","AS","var",")"]}, 
  "blankNode" : {
     "BLANK_NODE_LABEL": ["BLANK_NODE_LABEL"], 
     "ANON": ["ANON"]}, 
  "blankNodePropertyList" : {
     "[": ["[","propertyListNotEmpty","]"]}, 
  "blankNodePropertyListPath" : {
     "[": ["[","propertyListPathNotEmpty","]"]}, 
  "booleanLiteral" : {
     "TRUE": ["TRUE"], 
     "FALSE": ["FALSE"]}, 
  "brackettedExpression" : {
     "(": ["(","expression",")"]}, 
  "builtInCall" : {
     "STR": ["STR","(","expression",")"], 
     "LANG": ["LANG","(","expression",")"], 
     "LANGMATCHES": ["LANGMATCHES","(","expression",",","expression",")"], 
     "DATATYPE": ["DATATYPE","(","expression",")"], 
     "BOUND": ["BOUND","(","var",")"], 
     "IRI": ["IRI","(","expression",")"], 
     "URI": ["URI","(","expression",")"], 
     "BNODE": ["BNODE","or([[ (,expression,)],NIL])"], 
     "RAND": ["RAND","NIL"], 
     "ABS": ["ABS","(","expression",")"], 
     "CEIL": ["CEIL","(","expression",")"], 
     "FLOOR": ["FLOOR","(","expression",")"], 
     "ROUND": ["ROUND","(","expression",")"], 
     "CONCAT": ["CONCAT","expressionList"], 
     "SUBSTR": ["substringExpression"], 
     "STRLEN": ["STRLEN","(","expression",")"], 
     "REPLACE": ["strReplaceExpression"], 
     "UCASE": ["UCASE","(","expression",")"], 
     "LCASE": ["LCASE","(","expression",")"], 
     "ENCODE_FOR_URI": ["ENCODE_FOR_URI","(","expression",")"], 
     "CONTAINS": ["CONTAINS","(","expression",",","expression",")"], 
     "STRSTARTS": ["STRSTARTS","(","expression",",","expression",")"], 
     "STRENDS": ["STRENDS","(","expression",",","expression",")"], 
     "STRBEFORE": ["STRBEFORE","(","expression",",","expression",")"], 
     "STRAFTER": ["STRAFTER","(","expression",",","expression",")"], 
     "YEAR": ["YEAR","(","expression",")"], 
     "MONTH": ["MONTH","(","expression",")"], 
     "DAY": ["DAY","(","expression",")"], 
     "HOURS": ["HOURS","(","expression",")"], 
     "MINUTES": ["MINUTES","(","expression",")"], 
     "SECONDS": ["SECONDS","(","expression",")"], 
     "TIMEZONE": ["TIMEZONE","(","expression",")"], 
     "TZ": ["TZ","(","expression",")"], 
     "NOW": ["NOW","NIL"], 
     "UUID": ["UUID","NIL"], 
     "STRUUID": ["STRUUID","NIL"], 
     "MD5": ["MD5","(","expression",")"], 
     "SHA1": ["SHA1","(","expression",")"], 
     "SHA256": ["SHA256","(","expression",")"], 
     "SHA384": ["SHA384","(","expression",")"], 
     "SHA512": ["SHA512","(","expression",")"], 
     "COALESCE": ["COALESCE","expressionList"], 
     "IF": ["IF","(","expression",",","expression",",","expression",")"], 
     "STRLANG": ["STRLANG","(","expression",",","expression",")"], 
     "STRDT": ["STRDT","(","expression",",","expression",")"], 
     "SAMETERM": ["SAMETERM","(","expression",",","expression",")"], 
     "ISIRI": ["ISIRI","(","expression",")"], 
     "ISURI": ["ISURI","(","expression",")"], 
     "ISBLANK": ["ISBLANK","(","expression",")"], 
     "ISLITERAL": ["ISLITERAL","(","expression",")"], 
     "ISNUMERIC": ["ISNUMERIC","(","expression",")"], 
     "REGEX": ["regexExpression"], 
     "EXISTS": ["existsFunc"], 
     "NOT": ["notExistsFunc"]}, 
  "clear" : {
     "CLEAR": ["CLEAR","?SILENT_2","graphRefAll"]}, 
  "collection" : {
     "(": ["(","+graphNode",")"]}, 
  "collectionPath" : {
     "(": ["(","+graphNodePath",")"]}, 
  "conditionalAndExpression" : {
     "!": ["valueLogical","*[&&,valueLogical]"], 
     "+": ["valueLogical","*[&&,valueLogical]"], 
     "-": ["valueLogical","*[&&,valueLogical]"], 
     "VAR1": ["valueLogical","*[&&,valueLogical]"], 
     "VAR2": ["valueLogical","*[&&,valueLogical]"], 
     "(": ["valueLogical","*[&&,valueLogical]"], 
     "STR": ["valueLogical","*[&&,valueLogical]"], 
     "LANG": ["valueLogical","*[&&,valueLogical]"], 
     "LANGMATCHES": ["valueLogical","*[&&,valueLogical]"], 
     "DATATYPE": ["valueLogical","*[&&,valueLogical]"], 
     "BOUND": ["valueLogical","*[&&,valueLogical]"], 
     "IRI": ["valueLogical","*[&&,valueLogical]"], 
     "URI": ["valueLogical","*[&&,valueLogical]"], 
     "BNODE": ["valueLogical","*[&&,valueLogical]"], 
     "RAND": ["valueLogical","*[&&,valueLogical]"], 
     "ABS": ["valueLogical","*[&&,valueLogical]"], 
     "CEIL": ["valueLogical","*[&&,valueLogical]"], 
     "FLOOR": ["valueLogical","*[&&,valueLogical]"], 
     "ROUND": ["valueLogical","*[&&,valueLogical]"], 
     "CONCAT": ["valueLogical","*[&&,valueLogical]"], 
     "STRLEN": ["valueLogical","*[&&,valueLogical]"], 
     "UCASE": ["valueLogical","*[&&,valueLogical]"], 
     "LCASE": ["valueLogical","*[&&,valueLogical]"], 
     "ENCODE_FOR_URI": ["valueLogical","*[&&,valueLogical]"], 
     "CONTAINS": ["valueLogical","*[&&,valueLogical]"], 
     "STRSTARTS": ["valueLogical","*[&&,valueLogical]"], 
     "STRENDS": ["valueLogical","*[&&,valueLogical]"], 
     "STRBEFORE": ["valueLogical","*[&&,valueLogical]"], 
     "STRAFTER": ["valueLogical","*[&&,valueLogical]"], 
     "YEAR": ["valueLogical","*[&&,valueLogical]"], 
     "MONTH": ["valueLogical","*[&&,valueLogical]"], 
     "DAY": ["valueLogical","*[&&,valueLogical]"], 
     "HOURS": ["valueLogical","*[&&,valueLogical]"], 
     "MINUTES": ["valueLogical","*[&&,valueLogical]"], 
     "SECONDS": ["valueLogical","*[&&,valueLogical]"], 
     "TIMEZONE": ["valueLogical","*[&&,valueLogical]"], 
     "TZ": ["valueLogical","*[&&,valueLogical]"], 
     "NOW": ["valueLogical","*[&&,valueLogical]"], 
     "UUID": ["valueLogical","*[&&,valueLogical]"], 
     "STRUUID": ["valueLogical","*[&&,valueLogical]"], 
     "MD5": ["valueLogical","*[&&,valueLogical]"], 
     "SHA1": ["valueLogical","*[&&,valueLogical]"], 
     "SHA256": ["valueLogical","*[&&,valueLogical]"], 
     "SHA384": ["valueLogical","*[&&,valueLogical]"], 
     "SHA512": ["valueLogical","*[&&,valueLogical]"], 
     "COALESCE": ["valueLogical","*[&&,valueLogical]"], 
     "IF": ["valueLogical","*[&&,valueLogical]"], 
     "STRLANG": ["valueLogical","*[&&,valueLogical]"], 
     "STRDT": ["valueLogical","*[&&,valueLogical]"], 
     "SAMETERM": ["valueLogical","*[&&,valueLogical]"], 
     "ISIRI": ["valueLogical","*[&&,valueLogical]"], 
     "ISURI": ["valueLogical","*[&&,valueLogical]"], 
     "ISBLANK": ["valueLogical","*[&&,valueLogical]"], 
     "ISLITERAL": ["valueLogical","*[&&,valueLogical]"], 
     "ISNUMERIC": ["valueLogical","*[&&,valueLogical]"], 
     "TRUE": ["valueLogical","*[&&,valueLogical]"], 
     "FALSE": ["valueLogical","*[&&,valueLogical]"], 
     "COUNT": ["valueLogical","*[&&,valueLogical]"], 
     "SUM": ["valueLogical","*[&&,valueLogical]"], 
     "MIN": ["valueLogical","*[&&,valueLogical]"], 
     "MAX": ["valueLogical","*[&&,valueLogical]"], 
     "AVG": ["valueLogical","*[&&,valueLogical]"], 
     "SAMPLE": ["valueLogical","*[&&,valueLogical]"], 
     "GROUP_CONCAT": ["valueLogical","*[&&,valueLogical]"], 
     "SUBSTR": ["valueLogical","*[&&,valueLogical]"], 
     "REPLACE": ["valueLogical","*[&&,valueLogical]"], 
     "REGEX": ["valueLogical","*[&&,valueLogical]"], 
     "EXISTS": ["valueLogical","*[&&,valueLogical]"], 
     "NOT": ["valueLogical","*[&&,valueLogical]"], 
     "IRI_REF": ["valueLogical","*[&&,valueLogical]"], 
     "STRING_LITERAL1": ["valueLogical","*[&&,valueLogical]"], 
     "STRING_LITERAL2": ["valueLogical","*[&&,valueLogical]"], 
     "STRING_LITERAL_LONG1": ["valueLogical","*[&&,valueLogical]"], 
     "STRING_LITERAL_LONG2": ["valueLogical","*[&&,valueLogical]"], 
     "INTEGER": ["valueLogical","*[&&,valueLogical]"], 
     "DECIMAL": ["valueLogical","*[&&,valueLogical]"], 
     "DOUBLE": ["valueLogical","*[&&,valueLogical]"], 
     "INTEGER_POSITIVE": ["valueLogical","*[&&,valueLogical]"], 
     "DECIMAL_POSITIVE": ["valueLogical","*[&&,valueLogical]"], 
     "DOUBLE_POSITIVE": ["valueLogical","*[&&,valueLogical]"], 
     "INTEGER_NEGATIVE": ["valueLogical","*[&&,valueLogical]"], 
     "DECIMAL_NEGATIVE": ["valueLogical","*[&&,valueLogical]"], 
     "DOUBLE_NEGATIVE": ["valueLogical","*[&&,valueLogical]"], 
     "PNAME_LN": ["valueLogical","*[&&,valueLogical]"], 
     "PNAME_NS": ["valueLogical","*[&&,valueLogical]"]}, 
  "conditionalOrExpression" : {
     "!": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "+": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "-": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "VAR1": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "VAR2": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "(": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "STR": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "LANG": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "LANGMATCHES": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "DATATYPE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "BOUND": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "IRI": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "URI": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "BNODE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "RAND": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "ABS": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "CEIL": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "FLOOR": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "ROUND": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "CONCAT": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "STRLEN": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "UCASE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "LCASE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "ENCODE_FOR_URI": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "CONTAINS": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "STRSTARTS": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "STRENDS": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "STRBEFORE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "STRAFTER": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "YEAR": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "MONTH": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "DAY": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "HOURS": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "MINUTES": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "SECONDS": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "TIMEZONE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "TZ": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "NOW": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "UUID": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "STRUUID": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "MD5": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "SHA1": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "SHA256": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "SHA384": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "SHA512": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "COALESCE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "IF": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "STRLANG": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "STRDT": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "SAMETERM": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "ISIRI": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "ISURI": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "ISBLANK": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "ISLITERAL": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "ISNUMERIC": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "TRUE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "FALSE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "COUNT": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "SUM": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "MIN": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "MAX": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "AVG": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "SAMPLE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "GROUP_CONCAT": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "SUBSTR": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "REPLACE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "REGEX": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "EXISTS": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "NOT": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "IRI_REF": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "STRING_LITERAL1": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "STRING_LITERAL2": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "STRING_LITERAL_LONG1": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "STRING_LITERAL_LONG2": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "INTEGER": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "DECIMAL": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "DOUBLE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "INTEGER_POSITIVE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "DECIMAL_POSITIVE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "DOUBLE_POSITIVE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "INTEGER_NEGATIVE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "DECIMAL_NEGATIVE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "DOUBLE_NEGATIVE": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "PNAME_LN": ["conditionalAndExpression","*[||,conditionalAndExpression]"], 
     "PNAME_NS": ["conditionalAndExpression","*[||,conditionalAndExpression]"]}, 
  "constraint" : {
     "(": ["brackettedExpression"], 
     "STR": ["builtInCall"], 
     "LANG": ["builtInCall"], 
     "LANGMATCHES": ["builtInCall"], 
     "DATATYPE": ["builtInCall"], 
     "BOUND": ["builtInCall"], 
     "IRI": ["builtInCall"], 
     "URI": ["builtInCall"], 
     "BNODE": ["builtInCall"], 
     "RAND": ["builtInCall"], 
     "ABS": ["builtInCall"], 
     "CEIL": ["builtInCall"], 
     "FLOOR": ["builtInCall"], 
     "ROUND": ["builtInCall"], 
     "CONCAT": ["builtInCall"], 
     "STRLEN": ["builtInCall"], 
     "UCASE": ["builtInCall"], 
     "LCASE": ["builtInCall"], 
     "ENCODE_FOR_URI": ["builtInCall"], 
     "CONTAINS": ["builtInCall"], 
     "STRSTARTS": ["builtInCall"], 
     "STRENDS": ["builtInCall"], 
     "STRBEFORE": ["builtInCall"], 
     "STRAFTER": ["builtInCall"], 
     "YEAR": ["builtInCall"], 
     "MONTH": ["builtInCall"], 
     "DAY": ["builtInCall"], 
     "HOURS": ["builtInCall"], 
     "MINUTES": ["builtInCall"], 
     "SECONDS": ["builtInCall"], 
     "TIMEZONE": ["builtInCall"], 
     "TZ": ["builtInCall"], 
     "NOW": ["builtInCall"], 
     "UUID": ["builtInCall"], 
     "STRUUID": ["builtInCall"], 
     "MD5": ["builtInCall"], 
     "SHA1": ["builtInCall"], 
     "SHA256": ["builtInCall"], 
     "SHA384": ["builtInCall"], 
     "SHA512": ["builtInCall"], 
     "COALESCE": ["builtInCall"], 
     "IF": ["builtInCall"], 
     "STRLANG": ["builtInCall"], 
     "STRDT": ["builtInCall"], 
     "SAMETERM": ["builtInCall"], 
     "ISIRI": ["builtInCall"], 
     "ISURI": ["builtInCall"], 
     "ISBLANK": ["builtInCall"], 
     "ISLITERAL": ["builtInCall"], 
     "ISNUMERIC": ["builtInCall"], 
     "SUBSTR": ["builtInCall"], 
     "REPLACE": ["builtInCall"], 
     "REGEX": ["builtInCall"], 
     "EXISTS": ["builtInCall"], 
     "NOT": ["builtInCall"], 
     "IRI_REF": ["functionCall"], 
     "PNAME_LN": ["functionCall"], 
     "PNAME_NS": ["functionCall"]}, 
  "constructQuery" : {
     "CONSTRUCT": ["CONSTRUCT","or([[constructTemplate,*datasetClause,whereClause,solutionModifier],[*datasetClause,WHERE,{,?triplesTemplate,},solutionModifier]])"]}, 
  "constructTemplate" : {
     "{": ["{","?constructTriples","}"]}, 
  "constructTriples" : {
     "VAR1": ["triplesSameSubject","?[.,?constructTriples]"], 
     "VAR2": ["triplesSameSubject","?[.,?constructTriples]"], 
     "NIL": ["triplesSameSubject","?[.,?constructTriples]"], 
     "(": ["triplesSameSubject","?[.,?constructTriples]"], 
     "[": ["triplesSameSubject","?[.,?constructTriples]"], 
     "IRI_REF": ["triplesSameSubject","?[.,?constructTriples]"], 
     "TRUE": ["triplesSameSubject","?[.,?constructTriples]"], 
     "FALSE": ["triplesSameSubject","?[.,?constructTriples]"], 
     "BLANK_NODE_LABEL": ["triplesSameSubject","?[.,?constructTriples]"], 
     "ANON": ["triplesSameSubject","?[.,?constructTriples]"], 
     "PNAME_LN": ["triplesSameSubject","?[.,?constructTriples]"], 
     "PNAME_NS": ["triplesSameSubject","?[.,?constructTriples]"], 
     "STRING_LITERAL1": ["triplesSameSubject","?[.,?constructTriples]"], 
     "STRING_LITERAL2": ["triplesSameSubject","?[.,?constructTriples]"], 
     "STRING_LITERAL_LONG1": ["triplesSameSubject","?[.,?constructTriples]"], 
     "STRING_LITERAL_LONG2": ["triplesSameSubject","?[.,?constructTriples]"], 
     "INTEGER": ["triplesSameSubject","?[.,?constructTriples]"], 
     "DECIMAL": ["triplesSameSubject","?[.,?constructTriples]"], 
     "DOUBLE": ["triplesSameSubject","?[.,?constructTriples]"], 
     "INTEGER_POSITIVE": ["triplesSameSubject","?[.,?constructTriples]"], 
     "DECIMAL_POSITIVE": ["triplesSameSubject","?[.,?constructTriples]"], 
     "DOUBLE_POSITIVE": ["triplesSameSubject","?[.,?constructTriples]"], 
     "INTEGER_NEGATIVE": ["triplesSameSubject","?[.,?constructTriples]"], 
     "DECIMAL_NEGATIVE": ["triplesSameSubject","?[.,?constructTriples]"], 
     "DOUBLE_NEGATIVE": ["triplesSameSubject","?[.,?constructTriples]"]}, 
  "copy" : {
     "COPY": ["COPY","?SILENT_4","graphOrDefault","TO","graphOrDefault"]}, 
  "create" : {
     "CREATE": ["CREATE","?SILENT_3","graphRef"]}, 
  "dataBlock" : {
     "NIL": ["or([inlineDataOneVar,inlineDataFull])"], 
     "(": ["or([inlineDataOneVar,inlineDataFull])"], 
     "VAR1": ["or([inlineDataOneVar,inlineDataFull])"], 
     "VAR2": ["or([inlineDataOneVar,inlineDataFull])"]}, 
  "dataBlockValue" : {
     "IRI_REF": ["iriRef"], 
     "PNAME_LN": ["iriRef"], 
     "PNAME_NS": ["iriRef"], 
     "STRING_LITERAL1": ["rdfLiteral"], 
     "STRING_LITERAL2": ["rdfLiteral"], 
     "STRING_LITERAL_LONG1": ["rdfLiteral"], 
     "STRING_LITERAL_LONG2": ["rdfLiteral"], 
     "INTEGER": ["numericLiteral"], 
     "DECIMAL": ["numericLiteral"], 
     "DOUBLE": ["numericLiteral"], 
     "INTEGER_POSITIVE": ["numericLiteral"], 
     "DECIMAL_POSITIVE": ["numericLiteral"], 
     "DOUBLE_POSITIVE": ["numericLiteral"], 
     "INTEGER_NEGATIVE": ["numericLiteral"], 
     "DECIMAL_NEGATIVE": ["numericLiteral"], 
     "DOUBLE_NEGATIVE": ["numericLiteral"], 
     "TRUE": ["booleanLiteral"], 
     "FALSE": ["booleanLiteral"], 
     "UNDEF": ["UNDEF"]}, 
  "datasetClause" : {
     "FROM": ["FROM","or([defaultGraphClause,namedGraphClause])"]}, 
  "defaultGraphClause" : {
     "IRI_REF": ["sourceSelector"], 
     "PNAME_LN": ["sourceSelector"], 
     "PNAME_NS": ["sourceSelector"]}, 
  "delete1" : {
     "DATA": ["DATA","quadDataNoBnodes"], 
     "WHERE": ["WHERE","quadPatternNoBnodes"], 
     "{": ["quadPatternNoBnodes","?insertClause","*usingClause","WHERE","groupGraphPattern"]}, 
  "deleteClause" : {
     "DELETE": ["DELETE","quadPattern"]}, 
  "describeDatasetClause" : {
     "FROM": ["FROM","or([defaultGraphClause,namedGraphClause])"]}, 
  "describeQuery" : {
     "DESCRIBE": ["DESCRIBE","or([+varOrIRIref,*])","*describeDatasetClause","?whereClause","solutionModifier"]}, 
  "disallowBnodes" : {
     "}": [], 
     "GRAPH": [], 
     "VAR1": [], 
     "VAR2": [], 
     "NIL": [], 
     "(": [], 
     "[": [], 
     "IRI_REF": [], 
     "TRUE": [], 
     "FALSE": [], 
     "BLANK_NODE_LABEL": [], 
     "ANON": [], 
     "PNAME_LN": [], 
     "PNAME_NS": [], 
     "STRING_LITERAL1": [], 
     "STRING_LITERAL2": [], 
     "STRING_LITERAL_LONG1": [], 
     "STRING_LITERAL_LONG2": [], 
     "INTEGER": [], 
     "DECIMAL": [], 
     "DOUBLE": [], 
     "INTEGER_POSITIVE": [], 
     "DECIMAL_POSITIVE": [], 
     "DOUBLE_POSITIVE": [], 
     "INTEGER_NEGATIVE": [], 
     "DECIMAL_NEGATIVE": [], 
     "DOUBLE_NEGATIVE": []}, 
  "disallowVars" : {
     "}": [], 
     "GRAPH": [], 
     "VAR1": [], 
     "VAR2": [], 
     "NIL": [], 
     "(": [], 
     "[": [], 
     "IRI_REF": [], 
     "TRUE": [], 
     "FALSE": [], 
     "BLANK_NODE_LABEL": [], 
     "ANON": [], 
     "PNAME_LN": [], 
     "PNAME_NS": [], 
     "STRING_LITERAL1": [], 
     "STRING_LITERAL2": [], 
     "STRING_LITERAL_LONG1": [], 
     "STRING_LITERAL_LONG2": [], 
     "INTEGER": [], 
     "DECIMAL": [], 
     "DOUBLE": [], 
     "INTEGER_POSITIVE": [], 
     "DECIMAL_POSITIVE": [], 
     "DOUBLE_POSITIVE": [], 
     "INTEGER_NEGATIVE": [], 
     "DECIMAL_NEGATIVE": [], 
     "DOUBLE_NEGATIVE": []}, 
  "drop" : {
     "DROP": ["DROP","?SILENT_2","graphRefAll"]}, 
  "existsFunc" : {
     "EXISTS": ["EXISTS","groupGraphPattern"]}, 
  "expression" : {
     "!": ["conditionalOrExpression"], 
     "+": ["conditionalOrExpression"], 
     "-": ["conditionalOrExpression"], 
     "VAR1": ["conditionalOrExpression"], 
     "VAR2": ["conditionalOrExpression"], 
     "(": ["conditionalOrExpression"], 
     "STR": ["conditionalOrExpression"], 
     "LANG": ["conditionalOrExpression"], 
     "LANGMATCHES": ["conditionalOrExpression"], 
     "DATATYPE": ["conditionalOrExpression"], 
     "BOUND": ["conditionalOrExpression"], 
     "IRI": ["conditionalOrExpression"], 
     "URI": ["conditionalOrExpression"], 
     "BNODE": ["conditionalOrExpression"], 
     "RAND": ["conditionalOrExpression"], 
     "ABS": ["conditionalOrExpression"], 
     "CEIL": ["conditionalOrExpression"], 
     "FLOOR": ["conditionalOrExpression"], 
     "ROUND": ["conditionalOrExpression"], 
     "CONCAT": ["conditionalOrExpression"], 
     "STRLEN": ["conditionalOrExpression"], 
     "UCASE": ["conditionalOrExpression"], 
     "LCASE": ["conditionalOrExpression"], 
     "ENCODE_FOR_URI": ["conditionalOrExpression"], 
     "CONTAINS": ["conditionalOrExpression"], 
     "STRSTARTS": ["conditionalOrExpression"], 
     "STRENDS": ["conditionalOrExpression"], 
     "STRBEFORE": ["conditionalOrExpression"], 
     "STRAFTER": ["conditionalOrExpression"], 
     "YEAR": ["conditionalOrExpression"], 
     "MONTH": ["conditionalOrExpression"], 
     "DAY": ["conditionalOrExpression"], 
     "HOURS": ["conditionalOrExpression"], 
     "MINUTES": ["conditionalOrExpression"], 
     "SECONDS": ["conditionalOrExpression"], 
     "TIMEZONE": ["conditionalOrExpression"], 
     "TZ": ["conditionalOrExpression"], 
     "NOW": ["conditionalOrExpression"], 
     "UUID": ["conditionalOrExpression"], 
     "STRUUID": ["conditionalOrExpression"], 
     "MD5": ["conditionalOrExpression"], 
     "SHA1": ["conditionalOrExpression"], 
     "SHA256": ["conditionalOrExpression"], 
     "SHA384": ["conditionalOrExpression"], 
     "SHA512": ["conditionalOrExpression"], 
     "COALESCE": ["conditionalOrExpression"], 
     "IF": ["conditionalOrExpression"], 
     "STRLANG": ["conditionalOrExpression"], 
     "STRDT": ["conditionalOrExpression"], 
     "SAMETERM": ["conditionalOrExpression"], 
     "ISIRI": ["conditionalOrExpression"], 
     "ISURI": ["conditionalOrExpression"], 
     "ISBLANK": ["conditionalOrExpression"], 
     "ISLITERAL": ["conditionalOrExpression"], 
     "ISNUMERIC": ["conditionalOrExpression"], 
     "TRUE": ["conditionalOrExpression"], 
     "FALSE": ["conditionalOrExpression"], 
     "COUNT": ["conditionalOrExpression"], 
     "SUM": ["conditionalOrExpression"], 
     "MIN": ["conditionalOrExpression"], 
     "MAX": ["conditionalOrExpression"], 
     "AVG": ["conditionalOrExpression"], 
     "SAMPLE": ["conditionalOrExpression"], 
     "GROUP_CONCAT": ["conditionalOrExpression"], 
     "SUBSTR": ["conditionalOrExpression"], 
     "REPLACE": ["conditionalOrExpression"], 
     "REGEX": ["conditionalOrExpression"], 
     "EXISTS": ["conditionalOrExpression"], 
     "NOT": ["conditionalOrExpression"], 
     "IRI_REF": ["conditionalOrExpression"], 
     "STRING_LITERAL1": ["conditionalOrExpression"], 
     "STRING_LITERAL2": ["conditionalOrExpression"], 
     "STRING_LITERAL_LONG1": ["conditionalOrExpression"], 
     "STRING_LITERAL_LONG2": ["conditionalOrExpression"], 
     "INTEGER": ["conditionalOrExpression"], 
     "DECIMAL": ["conditionalOrExpression"], 
     "DOUBLE": ["conditionalOrExpression"], 
     "INTEGER_POSITIVE": ["conditionalOrExpression"], 
     "DECIMAL_POSITIVE": ["conditionalOrExpression"], 
     "DOUBLE_POSITIVE": ["conditionalOrExpression"], 
     "INTEGER_NEGATIVE": ["conditionalOrExpression"], 
     "DECIMAL_NEGATIVE": ["conditionalOrExpression"], 
     "DOUBLE_NEGATIVE": ["conditionalOrExpression"], 
     "PNAME_LN": ["conditionalOrExpression"], 
     "PNAME_NS": ["conditionalOrExpression"]}, 
  "expressionList" : {
     "NIL": ["NIL"], 
     "(": ["(","expression","*[,,expression]",")"]}, 
  "filter" : {
     "FILTER": ["FILTER","constraint"]}, 
  "functionCall" : {
     "IRI_REF": ["iriRef","argList"], 
     "PNAME_LN": ["iriRef","argList"], 
     "PNAME_NS": ["iriRef","argList"]}, 
  "graphGraphPattern" : {
     "GRAPH": ["GRAPH","varOrIRIref","groupGraphPattern"]}, 
  "graphNode" : {
     "VAR1": ["varOrTerm"], 
     "VAR2": ["varOrTerm"], 
     "NIL": ["varOrTerm"], 
     "IRI_REF": ["varOrTerm"], 
     "TRUE": ["varOrTerm"], 
     "FALSE": ["varOrTerm"], 
     "BLANK_NODE_LABEL": ["varOrTerm"], 
     "ANON": ["varOrTerm"], 
     "PNAME_LN": ["varOrTerm"], 
     "PNAME_NS": ["varOrTerm"], 
     "STRING_LITERAL1": ["varOrTerm"], 
     "STRING_LITERAL2": ["varOrTerm"], 
     "STRING_LITERAL_LONG1": ["varOrTerm"], 
     "STRING_LITERAL_LONG2": ["varOrTerm"], 
     "INTEGER": ["varOrTerm"], 
     "DECIMAL": ["varOrTerm"], 
     "DOUBLE": ["varOrTerm"], 
     "INTEGER_POSITIVE": ["varOrTerm"], 
     "DECIMAL_POSITIVE": ["varOrTerm"], 
     "DOUBLE_POSITIVE": ["varOrTerm"], 
     "INTEGER_NEGATIVE": ["varOrTerm"], 
     "DECIMAL_NEGATIVE": ["varOrTerm"], 
     "DOUBLE_NEGATIVE": ["varOrTerm"], 
     "(": ["triplesNode"], 
     "[": ["triplesNode"]}, 
  "graphNodePath" : {
     "VAR1": ["varOrTerm"], 
     "VAR2": ["varOrTerm"], 
     "NIL": ["varOrTerm"], 
     "IRI_REF": ["varOrTerm"], 
     "TRUE": ["varOrTerm"], 
     "FALSE": ["varOrTerm"], 
     "BLANK_NODE_LABEL": ["varOrTerm"], 
     "ANON": ["varOrTerm"], 
     "PNAME_LN": ["varOrTerm"], 
     "PNAME_NS": ["varOrTerm"], 
     "STRING_LITERAL1": ["varOrTerm"], 
     "STRING_LITERAL2": ["varOrTerm"], 
     "STRING_LITERAL_LONG1": ["varOrTerm"], 
     "STRING_LITERAL_LONG2": ["varOrTerm"], 
     "INTEGER": ["varOrTerm"], 
     "DECIMAL": ["varOrTerm"], 
     "DOUBLE": ["varOrTerm"], 
     "INTEGER_POSITIVE": ["varOrTerm"], 
     "DECIMAL_POSITIVE": ["varOrTerm"], 
     "DOUBLE_POSITIVE": ["varOrTerm"], 
     "INTEGER_NEGATIVE": ["varOrTerm"], 
     "DECIMAL_NEGATIVE": ["varOrTerm"], 
     "DOUBLE_NEGATIVE": ["varOrTerm"], 
     "(": ["triplesNodePath"], 
     "[": ["triplesNodePath"]}, 
  "graphOrDefault" : {
     "DEFAULT": ["DEFAULT"], 
     "IRI_REF": ["?GRAPH","iriRef"], 
     "PNAME_LN": ["?GRAPH","iriRef"], 
     "PNAME_NS": ["?GRAPH","iriRef"], 
     "GRAPH": ["?GRAPH","iriRef"]}, 
  "graphPatternNotTriples" : {
     "{": ["groupOrUnionGraphPattern"], 
     "OPTIONAL": ["optionalGraphPattern"], 
     "MINUS": ["minusGraphPattern"], 
     "GRAPH": ["graphGraphPattern"], 
     "SERVICE": ["serviceGraphPattern"], 
     "FILTER": ["filter"], 
     "BIND": ["bind"], 
     "VALUES": ["inlineData"]}, 
  "graphRef" : {
     "GRAPH": ["GRAPH","iriRef"]}, 
  "graphRefAll" : {
     "GRAPH": ["graphRef"], 
     "DEFAULT": ["DEFAULT"], 
     "NAMED": ["NAMED"], 
     "ALL": ["ALL"]}, 
  "graphTerm" : {
     "IRI_REF": ["iriRef"], 
     "PNAME_LN": ["iriRef"], 
     "PNAME_NS": ["iriRef"], 
     "STRING_LITERAL1": ["rdfLiteral"], 
     "STRING_LITERAL2": ["rdfLiteral"], 
     "STRING_LITERAL_LONG1": ["rdfLiteral"], 
     "STRING_LITERAL_LONG2": ["rdfLiteral"], 
     "INTEGER": ["numericLiteral"], 
     "DECIMAL": ["numericLiteral"], 
     "DOUBLE": ["numericLiteral"], 
     "INTEGER_POSITIVE": ["numericLiteral"], 
     "DECIMAL_POSITIVE": ["numericLiteral"], 
     "DOUBLE_POSITIVE": ["numericLiteral"], 
     "INTEGER_NEGATIVE": ["numericLiteral"], 
     "DECIMAL_NEGATIVE": ["numericLiteral"], 
     "DOUBLE_NEGATIVE": ["numericLiteral"], 
     "TRUE": ["booleanLiteral"], 
     "FALSE": ["booleanLiteral"], 
     "BLANK_NODE_LABEL": ["blankNode"], 
     "ANON": ["blankNode"], 
     "NIL": ["NIL"]}, 
  "groupClause" : {
     "GROUP": ["GROUP","BY","+groupCondition"]}, 
  "groupCondition" : {
     "STR": ["builtInCall"], 
     "LANG": ["builtInCall"], 
     "LANGMATCHES": ["builtInCall"], 
     "DATATYPE": ["builtInCall"], 
     "BOUND": ["builtInCall"], 
     "IRI": ["builtInCall"], 
     "URI": ["builtInCall"], 
     "BNODE": ["builtInCall"], 
     "RAND": ["builtInCall"], 
     "ABS": ["builtInCall"], 
     "CEIL": ["builtInCall"], 
     "FLOOR": ["builtInCall"], 
     "ROUND": ["builtInCall"], 
     "CONCAT": ["builtInCall"], 
     "STRLEN": ["builtInCall"], 
     "UCASE": ["builtInCall"], 
     "LCASE": ["builtInCall"], 
     "ENCODE_FOR_URI": ["builtInCall"], 
     "CONTAINS": ["builtInCall"], 
     "STRSTARTS": ["builtInCall"], 
     "STRENDS": ["builtInCall"], 
     "STRBEFORE": ["builtInCall"], 
     "STRAFTER": ["builtInCall"], 
     "YEAR": ["builtInCall"], 
     "MONTH": ["builtInCall"], 
     "DAY": ["builtInCall"], 
     "HOURS": ["builtInCall"], 
     "MINUTES": ["builtInCall"], 
     "SECONDS": ["builtInCall"], 
     "TIMEZONE": ["builtInCall"], 
     "TZ": ["builtInCall"], 
     "NOW": ["builtInCall"], 
     "UUID": ["builtInCall"], 
     "STRUUID": ["builtInCall"], 
     "MD5": ["builtInCall"], 
     "SHA1": ["builtInCall"], 
     "SHA256": ["builtInCall"], 
     "SHA384": ["builtInCall"], 
     "SHA512": ["builtInCall"], 
     "COALESCE": ["builtInCall"], 
     "IF": ["builtInCall"], 
     "STRLANG": ["builtInCall"], 
     "STRDT": ["builtInCall"], 
     "SAMETERM": ["builtInCall"], 
     "ISIRI": ["builtInCall"], 
     "ISURI": ["builtInCall"], 
     "ISBLANK": ["builtInCall"], 
     "ISLITERAL": ["builtInCall"], 
     "ISNUMERIC": ["builtInCall"], 
     "SUBSTR": ["builtInCall"], 
     "REPLACE": ["builtInCall"], 
     "REGEX": ["builtInCall"], 
     "EXISTS": ["builtInCall"], 
     "NOT": ["builtInCall"], 
     "IRI_REF": ["functionCall"], 
     "PNAME_LN": ["functionCall"], 
     "PNAME_NS": ["functionCall"], 
     "(": ["(","expression","?[AS,var]",")"], 
     "VAR1": ["var"], 
     "VAR2": ["var"]}, 
  "groupGraphPattern" : {
     "{": ["{","or([subSelect,groupGraphPatternSub])","}"]}, 
  "groupGraphPatternSub" : {
     "{": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "OPTIONAL": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "MINUS": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "GRAPH": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "SERVICE": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "FILTER": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "BIND": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "VALUES": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "VAR1": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "VAR2": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "NIL": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "(": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "[": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "IRI_REF": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "TRUE": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "FALSE": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "BLANK_NODE_LABEL": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "ANON": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "PNAME_LN": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "PNAME_NS": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "STRING_LITERAL1": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "STRING_LITERAL2": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "STRING_LITERAL_LONG1": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "STRING_LITERAL_LONG2": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "INTEGER": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "DECIMAL": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "DOUBLE": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "INTEGER_POSITIVE": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "DECIMAL_POSITIVE": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "DOUBLE_POSITIVE": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "INTEGER_NEGATIVE": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "DECIMAL_NEGATIVE": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "DOUBLE_NEGATIVE": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"], 
     "}": ["?triplesBlock","*[graphPatternNotTriples,?.,?triplesBlock]"]}, 
  "groupOrUnionGraphPattern" : {
     "{": ["groupGraphPattern","*[UNION,groupGraphPattern]"]}, 
  "havingClause" : {
     "HAVING": ["HAVING","+havingCondition"]}, 
  "havingCondition" : {
     "(": ["constraint"], 
     "STR": ["constraint"], 
     "LANG": ["constraint"], 
     "LANGMATCHES": ["constraint"], 
     "DATATYPE": ["constraint"], 
     "BOUND": ["constraint"], 
     "IRI": ["constraint"], 
     "URI": ["constraint"], 
     "BNODE": ["constraint"], 
     "RAND": ["constraint"], 
     "ABS": ["constraint"], 
     "CEIL": ["constraint"], 
     "FLOOR": ["constraint"], 
     "ROUND": ["constraint"], 
     "CONCAT": ["constraint"], 
     "STRLEN": ["constraint"], 
     "UCASE": ["constraint"], 
     "LCASE": ["constraint"], 
     "ENCODE_FOR_URI": ["constraint"], 
     "CONTAINS": ["constraint"], 
     "STRSTARTS": ["constraint"], 
     "STRENDS": ["constraint"], 
     "STRBEFORE": ["constraint"], 
     "STRAFTER": ["constraint"], 
     "YEAR": ["constraint"], 
     "MONTH": ["constraint"], 
     "DAY": ["constraint"], 
     "HOURS": ["constraint"], 
     "MINUTES": ["constraint"], 
     "SECONDS": ["constraint"], 
     "TIMEZONE": ["constraint"], 
     "TZ": ["constraint"], 
     "NOW": ["constraint"], 
     "UUID": ["constraint"], 
     "STRUUID": ["constraint"], 
     "MD5": ["constraint"], 
     "SHA1": ["constraint"], 
     "SHA256": ["constraint"], 
     "SHA384": ["constraint"], 
     "SHA512": ["constraint"], 
     "COALESCE": ["constraint"], 
     "IF": ["constraint"], 
     "STRLANG": ["constraint"], 
     "STRDT": ["constraint"], 
     "SAMETERM": ["constraint"], 
     "ISIRI": ["constraint"], 
     "ISURI": ["constraint"], 
     "ISBLANK": ["constraint"], 
     "ISLITERAL": ["constraint"], 
     "ISNUMERIC": ["constraint"], 
     "SUBSTR": ["constraint"], 
     "REPLACE": ["constraint"], 
     "REGEX": ["constraint"], 
     "EXISTS": ["constraint"], 
     "NOT": ["constraint"], 
     "IRI_REF": ["constraint"], 
     "PNAME_LN": ["constraint"], 
     "PNAME_NS": ["constraint"]}, 
  "inlineData" : {
     "VALUES": ["VALUES","dataBlock"]}, 
  "inlineDataFull" : {
     "NIL": ["or([NIL,[ (,*var,)]])","{","*or([[ (,*dataBlockValue,)],NIL])","}"], 
     "(": ["or([NIL,[ (,*var,)]])","{","*or([[ (,*dataBlockValue,)],NIL])","}"]}, 
  "inlineDataOneVar" : {
     "VAR1": ["var","{","*dataBlockValue","}"], 
     "VAR2": ["var","{","*dataBlockValue","}"]}, 
  "insert1" : {
     "DATA": ["DATA","quadData"], 
     "{": ["quadPattern","*usingClause","WHERE","groupGraphPattern"]}, 
  "insertClause" : {
     "INSERT": ["INSERT","quadPattern"]}, 
  "integer" : {
     "INTEGER": ["INTEGER"]}, 
  "iriRef" : {
     "IRI_REF": ["IRI_REF"], 
     "PNAME_LN": ["prefixedName"], 
     "PNAME_NS": ["prefixedName"]}, 
  "iriRefOrFunction" : {
     "IRI_REF": ["iriRef","?argList"], 
     "PNAME_LN": ["iriRef","?argList"], 
     "PNAME_NS": ["iriRef","?argList"]}, 
  "limitClause" : {
     "LIMIT": ["LIMIT","INTEGER"]}, 
  "limitOffsetClauses" : {
     "LIMIT": ["limitClause","?offsetClause"], 
     "OFFSET": ["offsetClause","?limitClause"]}, 
  "load" : {
     "LOAD": ["LOAD","?SILENT_1","iriRef","?[INTO,graphRef]"]}, 
  "minusGraphPattern" : {
     "MINUS": ["MINUS","groupGraphPattern"]}, 
  "modify" : {
     "WITH": ["WITH","iriRef","or([[deleteClause,?insertClause],insertClause])","*usingClause","WHERE","groupGraphPattern"]}, 
  "move" : {
     "MOVE": ["MOVE","?SILENT_4","graphOrDefault","TO","graphOrDefault"]}, 
  "multiplicativeExpression" : {
     "!": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "+": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "-": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "VAR1": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "VAR2": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "(": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "STR": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "LANG": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "LANGMATCHES": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "DATATYPE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "BOUND": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "IRI": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "URI": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "BNODE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "RAND": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "ABS": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "CEIL": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "FLOOR": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "ROUND": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "CONCAT": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "STRLEN": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "UCASE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "LCASE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "ENCODE_FOR_URI": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "CONTAINS": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "STRSTARTS": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "STRENDS": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "STRBEFORE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "STRAFTER": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "YEAR": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "MONTH": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "DAY": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "HOURS": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "MINUTES": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "SECONDS": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "TIMEZONE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "TZ": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "NOW": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "UUID": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "STRUUID": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "MD5": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "SHA1": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "SHA256": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "SHA384": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "SHA512": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "COALESCE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "IF": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "STRLANG": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "STRDT": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "SAMETERM": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "ISIRI": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "ISURI": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "ISBLANK": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "ISLITERAL": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "ISNUMERIC": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "TRUE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "FALSE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "COUNT": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "SUM": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "MIN": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "MAX": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "AVG": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "SAMPLE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "GROUP_CONCAT": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "SUBSTR": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "REPLACE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "REGEX": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "EXISTS": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "NOT": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "IRI_REF": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "STRING_LITERAL1": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "STRING_LITERAL2": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "STRING_LITERAL_LONG1": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "STRING_LITERAL_LONG2": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "INTEGER": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "DECIMAL": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "DOUBLE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "INTEGER_POSITIVE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "DECIMAL_POSITIVE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "DOUBLE_POSITIVE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "INTEGER_NEGATIVE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "DECIMAL_NEGATIVE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "DOUBLE_NEGATIVE": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "PNAME_LN": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"], 
     "PNAME_NS": ["unaryExpression","*or([[*,unaryExpression],[/,unaryExpression]])"]}, 
  "namedGraphClause" : {
     "NAMED": ["NAMED","sourceSelector"]}, 
  "notExistsFunc" : {
     "NOT": ["NOT","EXISTS","groupGraphPattern"]}, 
  "numericExpression" : {
     "!": ["additiveExpression"], 
     "+": ["additiveExpression"], 
     "-": ["additiveExpression"], 
     "VAR1": ["additiveExpression"], 
     "VAR2": ["additiveExpression"], 
     "(": ["additiveExpression"], 
     "STR": ["additiveExpression"], 
     "LANG": ["additiveExpression"], 
     "LANGMATCHES": ["additiveExpression"], 
     "DATATYPE": ["additiveExpression"], 
     "BOUND": ["additiveExpression"], 
     "IRI": ["additiveExpression"], 
     "URI": ["additiveExpression"], 
     "BNODE": ["additiveExpression"], 
     "RAND": ["additiveExpression"], 
     "ABS": ["additiveExpression"], 
     "CEIL": ["additiveExpression"], 
     "FLOOR": ["additiveExpression"], 
     "ROUND": ["additiveExpression"], 
     "CONCAT": ["additiveExpression"], 
     "STRLEN": ["additiveExpression"], 
     "UCASE": ["additiveExpression"], 
     "LCASE": ["additiveExpression"], 
     "ENCODE_FOR_URI": ["additiveExpression"], 
     "CONTAINS": ["additiveExpression"], 
     "STRSTARTS": ["additiveExpression"], 
     "STRENDS": ["additiveExpression"], 
     "STRBEFORE": ["additiveExpression"], 
     "STRAFTER": ["additiveExpression"], 
     "YEAR": ["additiveExpression"], 
     "MONTH": ["additiveExpression"], 
     "DAY": ["additiveExpression"], 
     "HOURS": ["additiveExpression"], 
     "MINUTES": ["additiveExpression"], 
     "SECONDS": ["additiveExpression"], 
     "TIMEZONE": ["additiveExpression"], 
     "TZ": ["additiveExpression"], 
     "NOW": ["additiveExpression"], 
     "UUID": ["additiveExpression"], 
     "STRUUID": ["additiveExpression"], 
     "MD5": ["additiveExpression"], 
     "SHA1": ["additiveExpression"], 
     "SHA256": ["additiveExpression"], 
     "SHA384": ["additiveExpression"], 
     "SHA512": ["additiveExpression"], 
     "COALESCE": ["additiveExpression"], 
     "IF": ["additiveExpression"], 
     "STRLANG": ["additiveExpression"], 
     "STRDT": ["additiveExpression"], 
     "SAMETERM": ["additiveExpression"], 
     "ISIRI": ["additiveExpression"], 
     "ISURI": ["additiveExpression"], 
     "ISBLANK": ["additiveExpression"], 
     "ISLITERAL": ["additiveExpression"], 
     "ISNUMERIC": ["additiveExpression"], 
     "TRUE": ["additiveExpression"], 
     "FALSE": ["additiveExpression"], 
     "COUNT": ["additiveExpression"], 
     "SUM": ["additiveExpression"], 
     "MIN": ["additiveExpression"], 
     "MAX": ["additiveExpression"], 
     "AVG": ["additiveExpression"], 
     "SAMPLE": ["additiveExpression"], 
     "GROUP_CONCAT": ["additiveExpression"], 
     "SUBSTR": ["additiveExpression"], 
     "REPLACE": ["additiveExpression"], 
     "REGEX": ["additiveExpression"], 
     "EXISTS": ["additiveExpression"], 
     "NOT": ["additiveExpression"], 
     "IRI_REF": ["additiveExpression"], 
     "STRING_LITERAL1": ["additiveExpression"], 
     "STRING_LITERAL2": ["additiveExpression"], 
     "STRING_LITERAL_LONG1": ["additiveExpression"], 
     "STRING_LITERAL_LONG2": ["additiveExpression"], 
     "INTEGER": ["additiveExpression"], 
     "DECIMAL": ["additiveExpression"], 
     "DOUBLE": ["additiveExpression"], 
     "INTEGER_POSITIVE": ["additiveExpression"], 
     "DECIMAL_POSITIVE": ["additiveExpression"], 
     "DOUBLE_POSITIVE": ["additiveExpression"], 
     "INTEGER_NEGATIVE": ["additiveExpression"], 
     "DECIMAL_NEGATIVE": ["additiveExpression"], 
     "DOUBLE_NEGATIVE": ["additiveExpression"], 
     "PNAME_LN": ["additiveExpression"], 
     "PNAME_NS": ["additiveExpression"]}, 
  "numericLiteral" : {
     "INTEGER": ["numericLiteralUnsigned"], 
     "DECIMAL": ["numericLiteralUnsigned"], 
     "DOUBLE": ["numericLiteralUnsigned"], 
     "INTEGER_POSITIVE": ["numericLiteralPositive"], 
     "DECIMAL_POSITIVE": ["numericLiteralPositive"], 
     "DOUBLE_POSITIVE": ["numericLiteralPositive"], 
     "INTEGER_NEGATIVE": ["numericLiteralNegative"], 
     "DECIMAL_NEGATIVE": ["numericLiteralNegative"], 
     "DOUBLE_NEGATIVE": ["numericLiteralNegative"]}, 
  "numericLiteralNegative" : {
     "INTEGER_NEGATIVE": ["INTEGER_NEGATIVE"], 
     "DECIMAL_NEGATIVE": ["DECIMAL_NEGATIVE"], 
     "DOUBLE_NEGATIVE": ["DOUBLE_NEGATIVE"]}, 
  "numericLiteralPositive" : {
     "INTEGER_POSITIVE": ["INTEGER_POSITIVE"], 
     "DECIMAL_POSITIVE": ["DECIMAL_POSITIVE"], 
     "DOUBLE_POSITIVE": ["DOUBLE_POSITIVE"]}, 
  "numericLiteralUnsigned" : {
     "INTEGER": ["INTEGER"], 
     "DECIMAL": ["DECIMAL"], 
     "DOUBLE": ["DOUBLE"]}, 
  "object" : {
     "(": ["graphNode"], 
     "[": ["graphNode"], 
     "VAR1": ["graphNode"], 
     "VAR2": ["graphNode"], 
     "NIL": ["graphNode"], 
     "IRI_REF": ["graphNode"], 
     "TRUE": ["graphNode"], 
     "FALSE": ["graphNode"], 
     "BLANK_NODE_LABEL": ["graphNode"], 
     "ANON": ["graphNode"], 
     "PNAME_LN": ["graphNode"], 
     "PNAME_NS": ["graphNode"], 
     "STRING_LITERAL1": ["graphNode"], 
     "STRING_LITERAL2": ["graphNode"], 
     "STRING_LITERAL_LONG1": ["graphNode"], 
     "STRING_LITERAL_LONG2": ["graphNode"], 
     "INTEGER": ["graphNode"], 
     "DECIMAL": ["graphNode"], 
     "DOUBLE": ["graphNode"], 
     "INTEGER_POSITIVE": ["graphNode"], 
     "DECIMAL_POSITIVE": ["graphNode"], 
     "DOUBLE_POSITIVE": ["graphNode"], 
     "INTEGER_NEGATIVE": ["graphNode"], 
     "DECIMAL_NEGATIVE": ["graphNode"], 
     "DOUBLE_NEGATIVE": ["graphNode"]}, 
  "objectList" : {
     "(": ["object","*[,,object]"], 
     "[": ["object","*[,,object]"], 
     "VAR1": ["object","*[,,object]"], 
     "VAR2": ["object","*[,,object]"], 
     "NIL": ["object","*[,,object]"], 
     "IRI_REF": ["object","*[,,object]"], 
     "TRUE": ["object","*[,,object]"], 
     "FALSE": ["object","*[,,object]"], 
     "BLANK_NODE_LABEL": ["object","*[,,object]"], 
     "ANON": ["object","*[,,object]"], 
     "PNAME_LN": ["object","*[,,object]"], 
     "PNAME_NS": ["object","*[,,object]"], 
     "STRING_LITERAL1": ["object","*[,,object]"], 
     "STRING_LITERAL2": ["object","*[,,object]"], 
     "STRING_LITERAL_LONG1": ["object","*[,,object]"], 
     "STRING_LITERAL_LONG2": ["object","*[,,object]"], 
     "INTEGER": ["object","*[,,object]"], 
     "DECIMAL": ["object","*[,,object]"], 
     "DOUBLE": ["object","*[,,object]"], 
     "INTEGER_POSITIVE": ["object","*[,,object]"], 
     "DECIMAL_POSITIVE": ["object","*[,,object]"], 
     "DOUBLE_POSITIVE": ["object","*[,,object]"], 
     "INTEGER_NEGATIVE": ["object","*[,,object]"], 
     "DECIMAL_NEGATIVE": ["object","*[,,object]"], 
     "DOUBLE_NEGATIVE": ["object","*[,,object]"]}, 
  "objectListPath" : {
     "(": ["objectPath","*[,,objectPath]"], 
     "[": ["objectPath","*[,,objectPath]"], 
     "VAR1": ["objectPath","*[,,objectPath]"], 
     "VAR2": ["objectPath","*[,,objectPath]"], 
     "NIL": ["objectPath","*[,,objectPath]"], 
     "IRI_REF": ["objectPath","*[,,objectPath]"], 
     "TRUE": ["objectPath","*[,,objectPath]"], 
     "FALSE": ["objectPath","*[,,objectPath]"], 
     "BLANK_NODE_LABEL": ["objectPath","*[,,objectPath]"], 
     "ANON": ["objectPath","*[,,objectPath]"], 
     "PNAME_LN": ["objectPath","*[,,objectPath]"], 
     "PNAME_NS": ["objectPath","*[,,objectPath]"], 
     "STRING_LITERAL1": ["objectPath","*[,,objectPath]"], 
     "STRING_LITERAL2": ["objectPath","*[,,objectPath]"], 
     "STRING_LITERAL_LONG1": ["objectPath","*[,,objectPath]"], 
     "STRING_LITERAL_LONG2": ["objectPath","*[,,objectPath]"], 
     "INTEGER": ["objectPath","*[,,objectPath]"], 
     "DECIMAL": ["objectPath","*[,,objectPath]"], 
     "DOUBLE": ["objectPath","*[,,objectPath]"], 
     "INTEGER_POSITIVE": ["objectPath","*[,,objectPath]"], 
     "DECIMAL_POSITIVE": ["objectPath","*[,,objectPath]"], 
     "DOUBLE_POSITIVE": ["objectPath","*[,,objectPath]"], 
     "INTEGER_NEGATIVE": ["objectPath","*[,,objectPath]"], 
     "DECIMAL_NEGATIVE": ["objectPath","*[,,objectPath]"], 
     "DOUBLE_NEGATIVE": ["objectPath","*[,,objectPath]"]}, 
  "objectPath" : {
     "(": ["graphNodePath"], 
     "[": ["graphNodePath"], 
     "VAR1": ["graphNodePath"], 
     "VAR2": ["graphNodePath"], 
     "NIL": ["graphNodePath"], 
     "IRI_REF": ["graphNodePath"], 
     "TRUE": ["graphNodePath"], 
     "FALSE": ["graphNodePath"], 
     "BLANK_NODE_LABEL": ["graphNodePath"], 
     "ANON": ["graphNodePath"], 
     "PNAME_LN": ["graphNodePath"], 
     "PNAME_NS": ["graphNodePath"], 
     "STRING_LITERAL1": ["graphNodePath"], 
     "STRING_LITERAL2": ["graphNodePath"], 
     "STRING_LITERAL_LONG1": ["graphNodePath"], 
     "STRING_LITERAL_LONG2": ["graphNodePath"], 
     "INTEGER": ["graphNodePath"], 
     "DECIMAL": ["graphNodePath"], 
     "DOUBLE": ["graphNodePath"], 
     "INTEGER_POSITIVE": ["graphNodePath"], 
     "DECIMAL_POSITIVE": ["graphNodePath"], 
     "DOUBLE_POSITIVE": ["graphNodePath"], 
     "INTEGER_NEGATIVE": ["graphNodePath"], 
     "DECIMAL_NEGATIVE": ["graphNodePath"], 
     "DOUBLE_NEGATIVE": ["graphNodePath"]}, 
  "offsetClause" : {
     "OFFSET": ["OFFSET","INTEGER"]}, 
  "optionalGraphPattern" : {
     "OPTIONAL": ["OPTIONAL","groupGraphPattern"]}, 
  "or([*,expression])" : {
     "*": ["*"], 
     "!": ["expression"], 
     "+": ["expression"], 
     "-": ["expression"], 
     "VAR1": ["expression"], 
     "VAR2": ["expression"], 
     "(": ["expression"], 
     "STR": ["expression"], 
     "LANG": ["expression"], 
     "LANGMATCHES": ["expression"], 
     "DATATYPE": ["expression"], 
     "BOUND": ["expression"], 
     "IRI": ["expression"], 
     "URI": ["expression"], 
     "BNODE": ["expression"], 
     "RAND": ["expression"], 
     "ABS": ["expression"], 
     "CEIL": ["expression"], 
     "FLOOR": ["expression"], 
     "ROUND": ["expression"], 
     "CONCAT": ["expression"], 
     "STRLEN": ["expression"], 
     "UCASE": ["expression"], 
     "LCASE": ["expression"], 
     "ENCODE_FOR_URI": ["expression"], 
     "CONTAINS": ["expression"], 
     "STRSTARTS": ["expression"], 
     "STRENDS": ["expression"], 
     "STRBEFORE": ["expression"], 
     "STRAFTER": ["expression"], 
     "YEAR": ["expression"], 
     "MONTH": ["expression"], 
     "DAY": ["expression"], 
     "HOURS": ["expression"], 
     "MINUTES": ["expression"], 
     "SECONDS": ["expression"], 
     "TIMEZONE": ["expression"], 
     "TZ": ["expression"], 
     "NOW": ["expression"], 
     "UUID": ["expression"], 
     "STRUUID": ["expression"], 
     "MD5": ["expression"], 
     "SHA1": ["expression"], 
     "SHA256": ["expression"], 
     "SHA384": ["expression"], 
     "SHA512": ["expression"], 
     "COALESCE": ["expression"], 
     "IF": ["expression"], 
     "STRLANG": ["expression"], 
     "STRDT": ["expression"], 
     "SAMETERM": ["expression"], 
     "ISIRI": ["expression"], 
     "ISURI": ["expression"], 
     "ISBLANK": ["expression"], 
     "ISLITERAL": ["expression"], 
     "ISNUMERIC": ["expression"], 
     "TRUE": ["expression"], 
     "FALSE": ["expression"], 
     "COUNT": ["expression"], 
     "SUM": ["expression"], 
     "MIN": ["expression"], 
     "MAX": ["expression"], 
     "AVG": ["expression"], 
     "SAMPLE": ["expression"], 
     "GROUP_CONCAT": ["expression"], 
     "SUBSTR": ["expression"], 
     "REPLACE": ["expression"], 
     "REGEX": ["expression"], 
     "EXISTS": ["expression"], 
     "NOT": ["expression"], 
     "IRI_REF": ["expression"], 
     "STRING_LITERAL1": ["expression"], 
     "STRING_LITERAL2": ["expression"], 
     "STRING_LITERAL_LONG1": ["expression"], 
     "STRING_LITERAL_LONG2": ["expression"], 
     "INTEGER": ["expression"], 
     "DECIMAL": ["expression"], 
     "DOUBLE": ["expression"], 
     "INTEGER_POSITIVE": ["expression"], 
     "DECIMAL_POSITIVE": ["expression"], 
     "DOUBLE_POSITIVE": ["expression"], 
     "INTEGER_NEGATIVE": ["expression"], 
     "DECIMAL_NEGATIVE": ["expression"], 
     "DOUBLE_NEGATIVE": ["expression"], 
     "PNAME_LN": ["expression"], 
     "PNAME_NS": ["expression"]}, 
  "or([+or([var,[ (,expression,AS,var,)]]),*])" : {
     "(": ["+or([var,[ (,expression,AS,var,)]])"], 
     "VAR1": ["+or([var,[ (,expression,AS,var,)]])"], 
     "VAR2": ["+or([var,[ (,expression,AS,var,)]])"], 
     "*": ["*"]}, 
  "or([+varOrIRIref,*])" : {
     "VAR1": ["+varOrIRIref"], 
     "VAR2": ["+varOrIRIref"], 
     "IRI_REF": ["+varOrIRIref"], 
     "PNAME_LN": ["+varOrIRIref"], 
     "PNAME_NS": ["+varOrIRIref"], 
     "*": ["*"]}, 
  "or([ASC,DESC])" : {
     "ASC": ["ASC"], 
     "DESC": ["DESC"]}, 
  "or([DISTINCT,REDUCED])" : {
     "DISTINCT": ["DISTINCT"], 
     "REDUCED": ["REDUCED"]}, 
  "or([LANGTAG,[^^,iriRef]])" : {
     "LANGTAG": ["LANGTAG"], 
     "^^": ["[^^,iriRef]"]}, 
  "or([NIL,[ (,*var,)]])" : {
     "NIL": ["NIL"], 
     "(": ["[ (,*var,)]"]}, 
  "or([[ (,*dataBlockValue,)],NIL])" : {
     "(": ["[ (,*dataBlockValue,)]"], 
     "NIL": ["NIL"]}, 
  "or([[ (,expression,)],NIL])" : {
     "(": ["[ (,expression,)]"], 
     "NIL": ["NIL"]}, 
  "or([[*,unaryExpression],[/,unaryExpression]])" : {
     "*": ["[*,unaryExpression]"], 
     "/": ["[/,unaryExpression]"]}, 
  "or([[+,multiplicativeExpression],[-,multiplicativeExpression],[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]])" : {
     "+": ["[+,multiplicativeExpression]"], 
     "-": ["[-,multiplicativeExpression]"], 
     "INTEGER_POSITIVE": ["[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]"], 
     "DECIMAL_POSITIVE": ["[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]"], 
     "DOUBLE_POSITIVE": ["[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]"], 
     "INTEGER_NEGATIVE": ["[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]"], 
     "DECIMAL_NEGATIVE": ["[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]"], 
     "DOUBLE_NEGATIVE": ["[or([numericLiteralPositive,numericLiteralNegative]),?or([[*,unaryExpression],[/,unaryExpression]])]"]}, 
  "or([[,,or([},[integer,}]])],}])" : {
     ",": ["[,,or([},[integer,}]])]"], 
     "}": ["}"]}, 
  "or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])" : {
     "=": ["[=,numericExpression]"], 
     "!=": ["[!=,numericExpression]"], 
     "<": ["[<,numericExpression]"], 
     ">": ["[>,numericExpression]"], 
     "<=": ["[<=,numericExpression]"], 
     ">=": ["[>=,numericExpression]"], 
     "IN": ["[IN,expressionList]"], 
     "NOT": ["[NOT,IN,expressionList]"]}, 
  "or([[constructTemplate,*datasetClause,whereClause,solutionModifier],[*datasetClause,WHERE,{,?triplesTemplate,},solutionModifier]])" : {
     "{": ["[constructTemplate,*datasetClause,whereClause,solutionModifier]"], 
     "WHERE": ["[*datasetClause,WHERE,{,?triplesTemplate,},solutionModifier]"], 
     "FROM": ["[*datasetClause,WHERE,{,?triplesTemplate,},solutionModifier]"]}, 
  "or([[deleteClause,?insertClause],insertClause])" : {
     "DELETE": ["[deleteClause,?insertClause]"], 
     "INSERT": ["insertClause"]}, 
  "or([[integer,or([[,,or([},[integer,}]])],}])],[,,integer,}]])" : {
     "INTEGER": ["[integer,or([[,,or([},[integer,}]])],}])]"], 
     ",": ["[,,integer,}]"]}, 
  "or([baseDecl,prefixDecl])" : {
     "BASE": ["baseDecl"], 
     "PREFIX": ["prefixDecl"]}, 
  "or([defaultGraphClause,namedGraphClause])" : {
     "IRI_REF": ["defaultGraphClause"], 
     "PNAME_LN": ["defaultGraphClause"], 
     "PNAME_NS": ["defaultGraphClause"], 
     "NAMED": ["namedGraphClause"]}, 
  "or([inlineDataOneVar,inlineDataFull])" : {
     "VAR1": ["inlineDataOneVar"], 
     "VAR2": ["inlineDataOneVar"], 
     "NIL": ["inlineDataFull"], 
     "(": ["inlineDataFull"]}, 
  "or([iriRef,[NAMED,iriRef]])" : {
     "IRI_REF": ["iriRef"], 
     "PNAME_LN": ["iriRef"], 
     "PNAME_NS": ["iriRef"], 
     "NAMED": ["[NAMED,iriRef]"]}, 
  "or([iriRef,a])" : {
     "IRI_REF": ["iriRef"], 
     "PNAME_LN": ["iriRef"], 
     "PNAME_NS": ["iriRef"], 
     "a": ["a"]}, 
  "or([numericLiteralPositive,numericLiteralNegative])" : {
     "INTEGER_POSITIVE": ["numericLiteralPositive"], 
     "DECIMAL_POSITIVE": ["numericLiteralPositive"], 
     "DOUBLE_POSITIVE": ["numericLiteralPositive"], 
     "INTEGER_NEGATIVE": ["numericLiteralNegative"], 
     "DECIMAL_NEGATIVE": ["numericLiteralNegative"], 
     "DOUBLE_NEGATIVE": ["numericLiteralNegative"]}, 
  "or([queryAll,updateAll])" : {
     "CONSTRUCT": ["queryAll"], 
     "DESCRIBE": ["queryAll"], 
     "ASK": ["queryAll"], 
     "SELECT": ["queryAll"], 
     "INSERT": ["updateAll"], 
     "DELETE": ["updateAll"], 
     "LOAD": ["updateAll"], 
     "CLEAR": ["updateAll"], 
     "DROP": ["updateAll"], 
     "ADD": ["updateAll"], 
     "MOVE": ["updateAll"], 
     "COPY": ["updateAll"], 
     "CREATE": ["updateAll"], 
     "WITH": ["updateAll"], 
     "$": ["updateAll"]}, 
  "or([selectQuery,constructQuery,describeQuery,askQuery])" : {
     "SELECT": ["selectQuery"], 
     "CONSTRUCT": ["constructQuery"], 
     "DESCRIBE": ["describeQuery"], 
     "ASK": ["askQuery"]}, 
  "or([subSelect,groupGraphPatternSub])" : {
     "SELECT": ["subSelect"], 
     "{": ["groupGraphPatternSub"], 
     "OPTIONAL": ["groupGraphPatternSub"], 
     "MINUS": ["groupGraphPatternSub"], 
     "GRAPH": ["groupGraphPatternSub"], 
     "SERVICE": ["groupGraphPatternSub"], 
     "FILTER": ["groupGraphPatternSub"], 
     "BIND": ["groupGraphPatternSub"], 
     "VALUES": ["groupGraphPatternSub"], 
     "VAR1": ["groupGraphPatternSub"], 
     "VAR2": ["groupGraphPatternSub"], 
     "NIL": ["groupGraphPatternSub"], 
     "(": ["groupGraphPatternSub"], 
     "[": ["groupGraphPatternSub"], 
     "IRI_REF": ["groupGraphPatternSub"], 
     "TRUE": ["groupGraphPatternSub"], 
     "FALSE": ["groupGraphPatternSub"], 
     "BLANK_NODE_LABEL": ["groupGraphPatternSub"], 
     "ANON": ["groupGraphPatternSub"], 
     "PNAME_LN": ["groupGraphPatternSub"], 
     "PNAME_NS": ["groupGraphPatternSub"], 
     "STRING_LITERAL1": ["groupGraphPatternSub"], 
     "STRING_LITERAL2": ["groupGraphPatternSub"], 
     "STRING_LITERAL_LONG1": ["groupGraphPatternSub"], 
     "STRING_LITERAL_LONG2": ["groupGraphPatternSub"], 
     "INTEGER": ["groupGraphPatternSub"], 
     "DECIMAL": ["groupGraphPatternSub"], 
     "DOUBLE": ["groupGraphPatternSub"], 
     "INTEGER_POSITIVE": ["groupGraphPatternSub"], 
     "DECIMAL_POSITIVE": ["groupGraphPatternSub"], 
     "DOUBLE_POSITIVE": ["groupGraphPatternSub"], 
     "INTEGER_NEGATIVE": ["groupGraphPatternSub"], 
     "DECIMAL_NEGATIVE": ["groupGraphPatternSub"], 
     "DOUBLE_NEGATIVE": ["groupGraphPatternSub"], 
     "}": ["groupGraphPatternSub"]}, 
  "or([var,[ (,expression,AS,var,)]])" : {
     "VAR1": ["var"], 
     "VAR2": ["var"], 
     "(": ["[ (,expression,AS,var,)]"]}, 
  "or([verbPath,verbSimple])" : {
     "^": ["verbPath"], 
     "a": ["verbPath"], 
     "!": ["verbPath"], 
     "(": ["verbPath"], 
     "IRI_REF": ["verbPath"], 
     "PNAME_LN": ["verbPath"], 
     "PNAME_NS": ["verbPath"], 
     "VAR1": ["verbSimple"], 
     "VAR2": ["verbSimple"]}, 
  "or([},[integer,}]])" : {
     "}": ["}"], 
     "INTEGER": ["[integer,}]"]}, 
  "orderClause" : {
     "ORDER": ["ORDER","BY","+orderCondition"]}, 
  "orderCondition" : {
     "ASC": ["or([ASC,DESC])","brackettedExpression"], 
     "DESC": ["or([ASC,DESC])","brackettedExpression"], 
     "(": ["constraint"], 
     "STR": ["constraint"], 
     "LANG": ["constraint"], 
     "LANGMATCHES": ["constraint"], 
     "DATATYPE": ["constraint"], 
     "BOUND": ["constraint"], 
     "IRI": ["constraint"], 
     "URI": ["constraint"], 
     "BNODE": ["constraint"], 
     "RAND": ["constraint"], 
     "ABS": ["constraint"], 
     "CEIL": ["constraint"], 
     "FLOOR": ["constraint"], 
     "ROUND": ["constraint"], 
     "CONCAT": ["constraint"], 
     "STRLEN": ["constraint"], 
     "UCASE": ["constraint"], 
     "LCASE": ["constraint"], 
     "ENCODE_FOR_URI": ["constraint"], 
     "CONTAINS": ["constraint"], 
     "STRSTARTS": ["constraint"], 
     "STRENDS": ["constraint"], 
     "STRBEFORE": ["constraint"], 
     "STRAFTER": ["constraint"], 
     "YEAR": ["constraint"], 
     "MONTH": ["constraint"], 
     "DAY": ["constraint"], 
     "HOURS": ["constraint"], 
     "MINUTES": ["constraint"], 
     "SECONDS": ["constraint"], 
     "TIMEZONE": ["constraint"], 
     "TZ": ["constraint"], 
     "NOW": ["constraint"], 
     "UUID": ["constraint"], 
     "STRUUID": ["constraint"], 
     "MD5": ["constraint"], 
     "SHA1": ["constraint"], 
     "SHA256": ["constraint"], 
     "SHA384": ["constraint"], 
     "SHA512": ["constraint"], 
     "COALESCE": ["constraint"], 
     "IF": ["constraint"], 
     "STRLANG": ["constraint"], 
     "STRDT": ["constraint"], 
     "SAMETERM": ["constraint"], 
     "ISIRI": ["constraint"], 
     "ISURI": ["constraint"], 
     "ISBLANK": ["constraint"], 
     "ISLITERAL": ["constraint"], 
     "ISNUMERIC": ["constraint"], 
     "SUBSTR": ["constraint"], 
     "REPLACE": ["constraint"], 
     "REGEX": ["constraint"], 
     "EXISTS": ["constraint"], 
     "NOT": ["constraint"], 
     "IRI_REF": ["constraint"], 
     "PNAME_LN": ["constraint"], 
     "PNAME_NS": ["constraint"], 
     "VAR1": ["var"], 
     "VAR2": ["var"]}, 
  "path" : {
     "^": ["pathAlternative"], 
     "a": ["pathAlternative"], 
     "!": ["pathAlternative"], 
     "(": ["pathAlternative"], 
     "IRI_REF": ["pathAlternative"], 
     "PNAME_LN": ["pathAlternative"], 
     "PNAME_NS": ["pathAlternative"]}, 
  "pathAlternative" : {
     "^": ["pathSequence","*[|,pathSequence]"], 
     "a": ["pathSequence","*[|,pathSequence]"], 
     "!": ["pathSequence","*[|,pathSequence]"], 
     "(": ["pathSequence","*[|,pathSequence]"], 
     "IRI_REF": ["pathSequence","*[|,pathSequence]"], 
     "PNAME_LN": ["pathSequence","*[|,pathSequence]"], 
     "PNAME_NS": ["pathSequence","*[|,pathSequence]"]}, 
  "pathElt" : {
     "a": ["pathPrimary","?pathMod"], 
     "!": ["pathPrimary","?pathMod"], 
     "(": ["pathPrimary","?pathMod"], 
     "IRI_REF": ["pathPrimary","?pathMod"], 
     "PNAME_LN": ["pathPrimary","?pathMod"], 
     "PNAME_NS": ["pathPrimary","?pathMod"]}, 
  "pathEltOrInverse" : {
     "a": ["pathElt"], 
     "!": ["pathElt"], 
     "(": ["pathElt"], 
     "IRI_REF": ["pathElt"], 
     "PNAME_LN": ["pathElt"], 
     "PNAME_NS": ["pathElt"], 
     "^": ["^","pathElt"]}, 
  "pathMod" : {
     "*": ["*"], 
     "?": ["?"], 
     "+": ["+"], 
     "{": ["{","or([[integer,or([[,,or([},[integer,}]])],}])],[,,integer,}]])"]}, 
  "pathNegatedPropertySet" : {
     "a": ["pathOneInPropertySet"], 
     "^": ["pathOneInPropertySet"], 
     "IRI_REF": ["pathOneInPropertySet"], 
     "PNAME_LN": ["pathOneInPropertySet"], 
     "PNAME_NS": ["pathOneInPropertySet"], 
     "(": ["(","?[pathOneInPropertySet,*[|,pathOneInPropertySet]]",")"]}, 
  "pathOneInPropertySet" : {
     "IRI_REF": ["iriRef"], 
     "PNAME_LN": ["iriRef"], 
     "PNAME_NS": ["iriRef"], 
     "a": ["a"], 
     "^": ["^","or([iriRef,a])"]}, 
  "pathPrimary" : {
     "IRI_REF": ["storeProperty","iriRef"], 
     "PNAME_LN": ["storeProperty","iriRef"], 
     "PNAME_NS": ["storeProperty","iriRef"], 
     "a": ["storeProperty","a"], 
     "!": ["!","pathNegatedPropertySet"], 
     "(": ["(","path",")"]}, 
  "pathSequence" : {
     "^": ["pathEltOrInverse","*[/,pathEltOrInverse]"], 
     "a": ["pathEltOrInverse","*[/,pathEltOrInverse]"], 
     "!": ["pathEltOrInverse","*[/,pathEltOrInverse]"], 
     "(": ["pathEltOrInverse","*[/,pathEltOrInverse]"], 
     "IRI_REF": ["pathEltOrInverse","*[/,pathEltOrInverse]"], 
     "PNAME_LN": ["pathEltOrInverse","*[/,pathEltOrInverse]"], 
     "PNAME_NS": ["pathEltOrInverse","*[/,pathEltOrInverse]"]}, 
  "prefixDecl" : {
     "PREFIX": ["PREFIX","PNAME_NS","IRI_REF"]}, 
  "prefixedName" : {
     "PNAME_LN": ["PNAME_LN"], 
     "PNAME_NS": ["PNAME_NS"]}, 
  "primaryExpression" : {
     "(": ["brackettedExpression"], 
     "STR": ["builtInCall"], 
     "LANG": ["builtInCall"], 
     "LANGMATCHES": ["builtInCall"], 
     "DATATYPE": ["builtInCall"], 
     "BOUND": ["builtInCall"], 
     "IRI": ["builtInCall"], 
     "URI": ["builtInCall"], 
     "BNODE": ["builtInCall"], 
     "RAND": ["builtInCall"], 
     "ABS": ["builtInCall"], 
     "CEIL": ["builtInCall"], 
     "FLOOR": ["builtInCall"], 
     "ROUND": ["builtInCall"], 
     "CONCAT": ["builtInCall"], 
     "STRLEN": ["builtInCall"], 
     "UCASE": ["builtInCall"], 
     "LCASE": ["builtInCall"], 
     "ENCODE_FOR_URI": ["builtInCall"], 
     "CONTAINS": ["builtInCall"], 
     "STRSTARTS": ["builtInCall"], 
     "STRENDS": ["builtInCall"], 
     "STRBEFORE": ["builtInCall"], 
     "STRAFTER": ["builtInCall"], 
     "YEAR": ["builtInCall"], 
     "MONTH": ["builtInCall"], 
     "DAY": ["builtInCall"], 
     "HOURS": ["builtInCall"], 
     "MINUTES": ["builtInCall"], 
     "SECONDS": ["builtInCall"], 
     "TIMEZONE": ["builtInCall"], 
     "TZ": ["builtInCall"], 
     "NOW": ["builtInCall"], 
     "UUID": ["builtInCall"], 
     "STRUUID": ["builtInCall"], 
     "MD5": ["builtInCall"], 
     "SHA1": ["builtInCall"], 
     "SHA256": ["builtInCall"], 
     "SHA384": ["builtInCall"], 
     "SHA512": ["builtInCall"], 
     "COALESCE": ["builtInCall"], 
     "IF": ["builtInCall"], 
     "STRLANG": ["builtInCall"], 
     "STRDT": ["builtInCall"], 
     "SAMETERM": ["builtInCall"], 
     "ISIRI": ["builtInCall"], 
     "ISURI": ["builtInCall"], 
     "ISBLANK": ["builtInCall"], 
     "ISLITERAL": ["builtInCall"], 
     "ISNUMERIC": ["builtInCall"], 
     "SUBSTR": ["builtInCall"], 
     "REPLACE": ["builtInCall"], 
     "REGEX": ["builtInCall"], 
     "EXISTS": ["builtInCall"], 
     "NOT": ["builtInCall"], 
     "IRI_REF": ["iriRefOrFunction"], 
     "PNAME_LN": ["iriRefOrFunction"], 
     "PNAME_NS": ["iriRefOrFunction"], 
     "STRING_LITERAL1": ["rdfLiteral"], 
     "STRING_LITERAL2": ["rdfLiteral"], 
     "STRING_LITERAL_LONG1": ["rdfLiteral"], 
     "STRING_LITERAL_LONG2": ["rdfLiteral"], 
     "INTEGER": ["numericLiteral"], 
     "DECIMAL": ["numericLiteral"], 
     "DOUBLE": ["numericLiteral"], 
     "INTEGER_POSITIVE": ["numericLiteral"], 
     "DECIMAL_POSITIVE": ["numericLiteral"], 
     "DOUBLE_POSITIVE": ["numericLiteral"], 
     "INTEGER_NEGATIVE": ["numericLiteral"], 
     "DECIMAL_NEGATIVE": ["numericLiteral"], 
     "DOUBLE_NEGATIVE": ["numericLiteral"], 
     "TRUE": ["booleanLiteral"], 
     "FALSE": ["booleanLiteral"], 
     "VAR1": ["var"], 
     "VAR2": ["var"], 
     "COUNT": ["aggregate"], 
     "SUM": ["aggregate"], 
     "MIN": ["aggregate"], 
     "MAX": ["aggregate"], 
     "AVG": ["aggregate"], 
     "SAMPLE": ["aggregate"], 
     "GROUP_CONCAT": ["aggregate"]}, 
  "prologue" : {
     "BASE": ["*or([baseDecl,prefixDecl])"], 
     "PREFIX": ["*or([baseDecl,prefixDecl])"], 
     "$": ["*or([baseDecl,prefixDecl])"], 
     "CONSTRUCT": ["*or([baseDecl,prefixDecl])"], 
     "DESCRIBE": ["*or([baseDecl,prefixDecl])"], 
     "ASK": ["*or([baseDecl,prefixDecl])"], 
     "INSERT": ["*or([baseDecl,prefixDecl])"], 
     "DELETE": ["*or([baseDecl,prefixDecl])"], 
     "SELECT": ["*or([baseDecl,prefixDecl])"], 
     "LOAD": ["*or([baseDecl,prefixDecl])"], 
     "CLEAR": ["*or([baseDecl,prefixDecl])"], 
     "DROP": ["*or([baseDecl,prefixDecl])"], 
     "ADD": ["*or([baseDecl,prefixDecl])"], 
     "MOVE": ["*or([baseDecl,prefixDecl])"], 
     "COPY": ["*or([baseDecl,prefixDecl])"], 
     "CREATE": ["*or([baseDecl,prefixDecl])"], 
     "WITH": ["*or([baseDecl,prefixDecl])"]}, 
  "propertyList" : {
     "a": ["propertyListNotEmpty"], 
     "VAR1": ["propertyListNotEmpty"], 
     "VAR2": ["propertyListNotEmpty"], 
     "IRI_REF": ["propertyListNotEmpty"], 
     "PNAME_LN": ["propertyListNotEmpty"], 
     "PNAME_NS": ["propertyListNotEmpty"], 
     ".": [], 
     "}": [], 
     "GRAPH": []}, 
  "propertyListNotEmpty" : {
     "a": ["verb","objectList","*[;,?[verb,objectList]]"], 
     "VAR1": ["verb","objectList","*[;,?[verb,objectList]]"], 
     "VAR2": ["verb","objectList","*[;,?[verb,objectList]]"], 
     "IRI_REF": ["verb","objectList","*[;,?[verb,objectList]]"], 
     "PNAME_LN": ["verb","objectList","*[;,?[verb,objectList]]"], 
     "PNAME_NS": ["verb","objectList","*[;,?[verb,objectList]]"]}, 
  "propertyListPath" : {
     "a": ["propertyListNotEmpty"], 
     "VAR1": ["propertyListNotEmpty"], 
     "VAR2": ["propertyListNotEmpty"], 
     "IRI_REF": ["propertyListNotEmpty"], 
     "PNAME_LN": ["propertyListNotEmpty"], 
     "PNAME_NS": ["propertyListNotEmpty"], 
     ".": [], 
     "{": [], 
     "OPTIONAL": [], 
     "MINUS": [], 
     "GRAPH": [], 
     "SERVICE": [], 
     "FILTER": [], 
     "BIND": [], 
     "VALUES": [], 
     "}": []}, 
  "propertyListPathNotEmpty" : {
     "VAR1": ["or([verbPath,verbSimple])","objectListPath","*[;,?[or([verbPath,verbSimple]),objectList]]"], 
     "VAR2": ["or([verbPath,verbSimple])","objectListPath","*[;,?[or([verbPath,verbSimple]),objectList]]"], 
     "^": ["or([verbPath,verbSimple])","objectListPath","*[;,?[or([verbPath,verbSimple]),objectList]]"], 
     "a": ["or([verbPath,verbSimple])","objectListPath","*[;,?[or([verbPath,verbSimple]),objectList]]"], 
     "!": ["or([verbPath,verbSimple])","objectListPath","*[;,?[or([verbPath,verbSimple]),objectList]]"], 
     "(": ["or([verbPath,verbSimple])","objectListPath","*[;,?[or([verbPath,verbSimple]),objectList]]"], 
     "IRI_REF": ["or([verbPath,verbSimple])","objectListPath","*[;,?[or([verbPath,verbSimple]),objectList]]"], 
     "PNAME_LN": ["or([verbPath,verbSimple])","objectListPath","*[;,?[or([verbPath,verbSimple]),objectList]]"], 
     "PNAME_NS": ["or([verbPath,verbSimple])","objectListPath","*[;,?[or([verbPath,verbSimple]),objectList]]"]}, 
  "quadData" : {
     "{": ["{","disallowVars","quads","allowVars","}"]}, 
  "quadDataNoBnodes" : {
     "{": ["{","disallowBnodes","disallowVars","quads","allowVars","allowBnodes","}"]}, 
  "quadPattern" : {
     "{": ["{","quads","}"]}, 
  "quadPatternNoBnodes" : {
     "{": ["{","disallowBnodes","quads","allowBnodes","}"]}, 
  "quads" : {
     "GRAPH": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "VAR1": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "VAR2": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "NIL": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "(": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "[": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "IRI_REF": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "TRUE": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "FALSE": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "BLANK_NODE_LABEL": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "ANON": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "PNAME_LN": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "PNAME_NS": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "STRING_LITERAL1": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "STRING_LITERAL2": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "STRING_LITERAL_LONG1": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "STRING_LITERAL_LONG2": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "INTEGER": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "DECIMAL": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "DOUBLE": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "INTEGER_POSITIVE": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "DECIMAL_POSITIVE": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "DOUBLE_POSITIVE": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "INTEGER_NEGATIVE": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "DECIMAL_NEGATIVE": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "DOUBLE_NEGATIVE": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"], 
     "}": ["?triplesTemplate","*[quadsNotTriples,?.,?triplesTemplate]"]}, 
  "quadsNotTriples" : {
     "GRAPH": ["GRAPH","varOrIRIref","{","?triplesTemplate","}"]}, 
  "queryAll" : {
     "CONSTRUCT": ["or([selectQuery,constructQuery,describeQuery,askQuery])","valuesClause"], 
     "DESCRIBE": ["or([selectQuery,constructQuery,describeQuery,askQuery])","valuesClause"], 
     "ASK": ["or([selectQuery,constructQuery,describeQuery,askQuery])","valuesClause"], 
     "SELECT": ["or([selectQuery,constructQuery,describeQuery,askQuery])","valuesClause"]}, 
  "rdfLiteral" : {
     "STRING_LITERAL1": ["string","?or([LANGTAG,[^^,iriRef]])"], 
     "STRING_LITERAL2": ["string","?or([LANGTAG,[^^,iriRef]])"], 
     "STRING_LITERAL_LONG1": ["string","?or([LANGTAG,[^^,iriRef]])"], 
     "STRING_LITERAL_LONG2": ["string","?or([LANGTAG,[^^,iriRef]])"]}, 
  "regexExpression" : {
     "REGEX": ["REGEX","(","expression",",","expression","?[,,expression]",")"]}, 
  "relationalExpression" : {
     "!": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "+": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "-": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "VAR1": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "VAR2": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "(": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "STR": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "LANG": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "LANGMATCHES": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "DATATYPE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "BOUND": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "IRI": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "URI": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "BNODE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "RAND": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "ABS": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "CEIL": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "FLOOR": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "ROUND": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "CONCAT": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "STRLEN": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "UCASE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "LCASE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "ENCODE_FOR_URI": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "CONTAINS": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "STRSTARTS": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "STRENDS": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "STRBEFORE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "STRAFTER": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "YEAR": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "MONTH": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "DAY": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "HOURS": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "MINUTES": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "SECONDS": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "TIMEZONE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "TZ": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "NOW": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "UUID": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "STRUUID": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "MD5": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "SHA1": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "SHA256": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "SHA384": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "SHA512": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "COALESCE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "IF": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "STRLANG": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "STRDT": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "SAMETERM": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "ISIRI": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "ISURI": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "ISBLANK": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "ISLITERAL": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "ISNUMERIC": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "TRUE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "FALSE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "COUNT": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "SUM": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "MIN": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "MAX": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "AVG": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "SAMPLE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "GROUP_CONCAT": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "SUBSTR": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "REPLACE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "REGEX": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "EXISTS": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "NOT": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "IRI_REF": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "STRING_LITERAL1": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "STRING_LITERAL2": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "STRING_LITERAL_LONG1": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "STRING_LITERAL_LONG2": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "INTEGER": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "DECIMAL": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "DOUBLE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "INTEGER_POSITIVE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "DECIMAL_POSITIVE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "DOUBLE_POSITIVE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "INTEGER_NEGATIVE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "DECIMAL_NEGATIVE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "DOUBLE_NEGATIVE": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "PNAME_LN": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"], 
     "PNAME_NS": ["numericExpression","?or([[=,numericExpression],[!=,numericExpression],[<,numericExpression],[>,numericExpression],[<=,numericExpression],[>=,numericExpression],[IN,expressionList],[NOT,IN,expressionList]])"]}, 
  "selectClause" : {
     "SELECT": ["SELECT","?or([DISTINCT,REDUCED])","or([+or([var,[ (,expression,AS,var,)]]),*])"]}, 
  "selectQuery" : {
     "SELECT": ["selectClause","*datasetClause","whereClause","solutionModifier"]}, 
  "serviceGraphPattern" : {
     "SERVICE": ["SERVICE","?SILENT","varOrIRIref","groupGraphPattern"]}, 
  "solutionModifier" : {
     "LIMIT": ["?groupClause","?havingClause","?orderClause","?limitOffsetClauses"], 
     "OFFSET": ["?groupClause","?havingClause","?orderClause","?limitOffsetClauses"], 
     "ORDER": ["?groupClause","?havingClause","?orderClause","?limitOffsetClauses"], 
     "HAVING": ["?groupClause","?havingClause","?orderClause","?limitOffsetClauses"], 
     "GROUP": ["?groupClause","?havingClause","?orderClause","?limitOffsetClauses"], 
     "VALUES": ["?groupClause","?havingClause","?orderClause","?limitOffsetClauses"], 
     "$": ["?groupClause","?havingClause","?orderClause","?limitOffsetClauses"], 
     "}": ["?groupClause","?havingClause","?orderClause","?limitOffsetClauses"]}, 
  "sourceSelector" : {
     "IRI_REF": ["iriRef"], 
     "PNAME_LN": ["iriRef"], 
     "PNAME_NS": ["iriRef"]}, 
  "sparql11" : {
     "$": ["prologue","or([queryAll,updateAll])","$"], 
     "CONSTRUCT": ["prologue","or([queryAll,updateAll])","$"], 
     "DESCRIBE": ["prologue","or([queryAll,updateAll])","$"], 
     "ASK": ["prologue","or([queryAll,updateAll])","$"], 
     "INSERT": ["prologue","or([queryAll,updateAll])","$"], 
     "DELETE": ["prologue","or([queryAll,updateAll])","$"], 
     "SELECT": ["prologue","or([queryAll,updateAll])","$"], 
     "LOAD": ["prologue","or([queryAll,updateAll])","$"], 
     "CLEAR": ["prologue","or([queryAll,updateAll])","$"], 
     "DROP": ["prologue","or([queryAll,updateAll])","$"], 
     "ADD": ["prologue","or([queryAll,updateAll])","$"], 
     "MOVE": ["prologue","or([queryAll,updateAll])","$"], 
     "COPY": ["prologue","or([queryAll,updateAll])","$"], 
     "CREATE": ["prologue","or([queryAll,updateAll])","$"], 
     "WITH": ["prologue","or([queryAll,updateAll])","$"], 
     "BASE": ["prologue","or([queryAll,updateAll])","$"], 
     "PREFIX": ["prologue","or([queryAll,updateAll])","$"]}, 
  "storeProperty" : {
     "VAR1": [], 
     "VAR2": [], 
     "IRI_REF": [], 
     "PNAME_LN": [], 
     "PNAME_NS": [], 
     "a": []}, 
  "strReplaceExpression" : {
     "REPLACE": ["REPLACE","(","expression",",","expression",",","expression","?[,,expression]",")"]}, 
  "string" : {
     "STRING_LITERAL1": ["STRING_LITERAL1"], 
     "STRING_LITERAL2": ["STRING_LITERAL2"], 
     "STRING_LITERAL_LONG1": ["STRING_LITERAL_LONG1"], 
     "STRING_LITERAL_LONG2": ["STRING_LITERAL_LONG2"]}, 
  "subSelect" : {
     "SELECT": ["selectClause","whereClause","solutionModifier","valuesClause"]}, 
  "substringExpression" : {
     "SUBSTR": ["SUBSTR","(","expression",",","expression","?[,,expression]",")"]}, 
  "triplesBlock" : {
     "VAR1": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "VAR2": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "NIL": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "(": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "[": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "IRI_REF": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "TRUE": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "FALSE": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "BLANK_NODE_LABEL": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "ANON": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "PNAME_LN": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "PNAME_NS": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "STRING_LITERAL1": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "STRING_LITERAL2": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "STRING_LITERAL_LONG1": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "STRING_LITERAL_LONG2": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "INTEGER": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "DECIMAL": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "DOUBLE": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "INTEGER_POSITIVE": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "DECIMAL_POSITIVE": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "DOUBLE_POSITIVE": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "INTEGER_NEGATIVE": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "DECIMAL_NEGATIVE": ["triplesSameSubjectPath","?[.,?triplesBlock]"], 
     "DOUBLE_NEGATIVE": ["triplesSameSubjectPath","?[.,?triplesBlock]"]}, 
  "triplesNode" : {
     "(": ["collection"], 
     "[": ["blankNodePropertyList"]}, 
  "triplesNodePath" : {
     "(": ["collectionPath"], 
     "[": ["blankNodePropertyListPath"]}, 
  "triplesSameSubject" : {
     "VAR1": ["varOrTerm","propertyListNotEmpty"], 
     "VAR2": ["varOrTerm","propertyListNotEmpty"], 
     "NIL": ["varOrTerm","propertyListNotEmpty"], 
     "IRI_REF": ["varOrTerm","propertyListNotEmpty"], 
     "TRUE": ["varOrTerm","propertyListNotEmpty"], 
     "FALSE": ["varOrTerm","propertyListNotEmpty"], 
     "BLANK_NODE_LABEL": ["varOrTerm","propertyListNotEmpty"], 
     "ANON": ["varOrTerm","propertyListNotEmpty"], 
     "PNAME_LN": ["varOrTerm","propertyListNotEmpty"], 
     "PNAME_NS": ["varOrTerm","propertyListNotEmpty"], 
     "STRING_LITERAL1": ["varOrTerm","propertyListNotEmpty"], 
     "STRING_LITERAL2": ["varOrTerm","propertyListNotEmpty"], 
     "STRING_LITERAL_LONG1": ["varOrTerm","propertyListNotEmpty"], 
     "STRING_LITERAL_LONG2": ["varOrTerm","propertyListNotEmpty"], 
     "INTEGER": ["varOrTerm","propertyListNotEmpty"], 
     "DECIMAL": ["varOrTerm","propertyListNotEmpty"], 
     "DOUBLE": ["varOrTerm","propertyListNotEmpty"], 
     "INTEGER_POSITIVE": ["varOrTerm","propertyListNotEmpty"], 
     "DECIMAL_POSITIVE": ["varOrTerm","propertyListNotEmpty"], 
     "DOUBLE_POSITIVE": ["varOrTerm","propertyListNotEmpty"], 
     "INTEGER_NEGATIVE": ["varOrTerm","propertyListNotEmpty"], 
     "DECIMAL_NEGATIVE": ["varOrTerm","propertyListNotEmpty"], 
     "DOUBLE_NEGATIVE": ["varOrTerm","propertyListNotEmpty"], 
     "(": ["triplesNode","propertyList"], 
     "[": ["triplesNode","propertyList"]}, 
  "triplesSameSubjectPath" : {
     "VAR1": ["varOrTerm","propertyListPathNotEmpty"], 
     "VAR2": ["varOrTerm","propertyListPathNotEmpty"], 
     "NIL": ["varOrTerm","propertyListPathNotEmpty"], 
     "IRI_REF": ["varOrTerm","propertyListPathNotEmpty"], 
     "TRUE": ["varOrTerm","propertyListPathNotEmpty"], 
     "FALSE": ["varOrTerm","propertyListPathNotEmpty"], 
     "BLANK_NODE_LABEL": ["varOrTerm","propertyListPathNotEmpty"], 
     "ANON": ["varOrTerm","propertyListPathNotEmpty"], 
     "PNAME_LN": ["varOrTerm","propertyListPathNotEmpty"], 
     "PNAME_NS": ["varOrTerm","propertyListPathNotEmpty"], 
     "STRING_LITERAL1": ["varOrTerm","propertyListPathNotEmpty"], 
     "STRING_LITERAL2": ["varOrTerm","propertyListPathNotEmpty"], 
     "STRING_LITERAL_LONG1": ["varOrTerm","propertyListPathNotEmpty"], 
     "STRING_LITERAL_LONG2": ["varOrTerm","propertyListPathNotEmpty"], 
     "INTEGER": ["varOrTerm","propertyListPathNotEmpty"], 
     "DECIMAL": ["varOrTerm","propertyListPathNotEmpty"], 
     "DOUBLE": ["varOrTerm","propertyListPathNotEmpty"], 
     "INTEGER_POSITIVE": ["varOrTerm","propertyListPathNotEmpty"], 
     "DECIMAL_POSITIVE": ["varOrTerm","propertyListPathNotEmpty"], 
     "DOUBLE_POSITIVE": ["varOrTerm","propertyListPathNotEmpty"], 
     "INTEGER_NEGATIVE": ["varOrTerm","propertyListPathNotEmpty"], 
     "DECIMAL_NEGATIVE": ["varOrTerm","propertyListPathNotEmpty"], 
     "DOUBLE_NEGATIVE": ["varOrTerm","propertyListPathNotEmpty"], 
     "(": ["triplesNodePath","propertyListPath"], 
     "[": ["triplesNodePath","propertyListPath"]}, 
  "triplesTemplate" : {
     "VAR1": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "VAR2": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "NIL": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "(": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "[": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "IRI_REF": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "TRUE": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "FALSE": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "BLANK_NODE_LABEL": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "ANON": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "PNAME_LN": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "PNAME_NS": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "STRING_LITERAL1": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "STRING_LITERAL2": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "STRING_LITERAL_LONG1": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "STRING_LITERAL_LONG2": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "INTEGER": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "DECIMAL": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "DOUBLE": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "INTEGER_POSITIVE": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "DECIMAL_POSITIVE": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "DOUBLE_POSITIVE": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "INTEGER_NEGATIVE": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "DECIMAL_NEGATIVE": ["triplesSameSubject","?[.,?triplesTemplate]"], 
     "DOUBLE_NEGATIVE": ["triplesSameSubject","?[.,?triplesTemplate]"]}, 
  "unaryExpression" : {
     "!": ["!","primaryExpression"], 
     "+": ["+","primaryExpression"], 
     "-": ["-","primaryExpression"], 
     "VAR1": ["primaryExpression"], 
     "VAR2": ["primaryExpression"], 
     "(": ["primaryExpression"], 
     "STR": ["primaryExpression"], 
     "LANG": ["primaryExpression"], 
     "LANGMATCHES": ["primaryExpression"], 
     "DATATYPE": ["primaryExpression"], 
     "BOUND": ["primaryExpression"], 
     "IRI": ["primaryExpression"], 
     "URI": ["primaryExpression"], 
     "BNODE": ["primaryExpression"], 
     "RAND": ["primaryExpression"], 
     "ABS": ["primaryExpression"], 
     "CEIL": ["primaryExpression"], 
     "FLOOR": ["primaryExpression"], 
     "ROUND": ["primaryExpression"], 
     "CONCAT": ["primaryExpression"], 
     "STRLEN": ["primaryExpression"], 
     "UCASE": ["primaryExpression"], 
     "LCASE": ["primaryExpression"], 
     "ENCODE_FOR_URI": ["primaryExpression"], 
     "CONTAINS": ["primaryExpression"], 
     "STRSTARTS": ["primaryExpression"], 
     "STRENDS": ["primaryExpression"], 
     "STRBEFORE": ["primaryExpression"], 
     "STRAFTER": ["primaryExpression"], 
     "YEAR": ["primaryExpression"], 
     "MONTH": ["primaryExpression"], 
     "DAY": ["primaryExpression"], 
     "HOURS": ["primaryExpression"], 
     "MINUTES": ["primaryExpression"], 
     "SECONDS": ["primaryExpression"], 
     "TIMEZONE": ["primaryExpression"], 
     "TZ": ["primaryExpression"], 
     "NOW": ["primaryExpression"], 
     "UUID": ["primaryExpression"], 
     "STRUUID": ["primaryExpression"], 
     "MD5": ["primaryExpression"], 
     "SHA1": ["primaryExpression"], 
     "SHA256": ["primaryExpression"], 
     "SHA384": ["primaryExpression"], 
     "SHA512": ["primaryExpression"], 
     "COALESCE": ["primaryExpression"], 
     "IF": ["primaryExpression"], 
     "STRLANG": ["primaryExpression"], 
     "STRDT": ["primaryExpression"], 
     "SAMETERM": ["primaryExpression"], 
     "ISIRI": ["primaryExpression"], 
     "ISURI": ["primaryExpression"], 
     "ISBLANK": ["primaryExpression"], 
     "ISLITERAL": ["primaryExpression"], 
     "ISNUMERIC": ["primaryExpression"], 
     "TRUE": ["primaryExpression"], 
     "FALSE": ["primaryExpression"], 
     "COUNT": ["primaryExpression"], 
     "SUM": ["primaryExpression"], 
     "MIN": ["primaryExpression"], 
     "MAX": ["primaryExpression"], 
     "AVG": ["primaryExpression"], 
     "SAMPLE": ["primaryExpression"], 
     "GROUP_CONCAT": ["primaryExpression"], 
     "SUBSTR": ["primaryExpression"], 
     "REPLACE": ["primaryExpression"], 
     "REGEX": ["primaryExpression"], 
     "EXISTS": ["primaryExpression"], 
     "NOT": ["primaryExpression"], 
     "IRI_REF": ["primaryExpression"], 
     "STRING_LITERAL1": ["primaryExpression"], 
     "STRING_LITERAL2": ["primaryExpression"], 
     "STRING_LITERAL_LONG1": ["primaryExpression"], 
     "STRING_LITERAL_LONG2": ["primaryExpression"], 
     "INTEGER": ["primaryExpression"], 
     "DECIMAL": ["primaryExpression"], 
     "DOUBLE": ["primaryExpression"], 
     "INTEGER_POSITIVE": ["primaryExpression"], 
     "DECIMAL_POSITIVE": ["primaryExpression"], 
     "DOUBLE_POSITIVE": ["primaryExpression"], 
     "INTEGER_NEGATIVE": ["primaryExpression"], 
     "DECIMAL_NEGATIVE": ["primaryExpression"], 
     "DOUBLE_NEGATIVE": ["primaryExpression"], 
     "PNAME_LN": ["primaryExpression"], 
     "PNAME_NS": ["primaryExpression"]}, 
  "update" : {
     "INSERT": ["prologue","?[update1,?[;,update]]"], 
     "DELETE": ["prologue","?[update1,?[;,update]]"], 
     "LOAD": ["prologue","?[update1,?[;,update]]"], 
     "CLEAR": ["prologue","?[update1,?[;,update]]"], 
     "DROP": ["prologue","?[update1,?[;,update]]"], 
     "ADD": ["prologue","?[update1,?[;,update]]"], 
     "MOVE": ["prologue","?[update1,?[;,update]]"], 
     "COPY": ["prologue","?[update1,?[;,update]]"], 
     "CREATE": ["prologue","?[update1,?[;,update]]"], 
     "WITH": ["prologue","?[update1,?[;,update]]"], 
     "BASE": ["prologue","?[update1,?[;,update]]"], 
     "PREFIX": ["prologue","?[update1,?[;,update]]"], 
     "$": ["prologue","?[update1,?[;,update]]"]}, 
  "update1" : {
     "LOAD": ["load"], 
     "CLEAR": ["clear"], 
     "DROP": ["drop"], 
     "ADD": ["add"], 
     "MOVE": ["move"], 
     "COPY": ["copy"], 
     "CREATE": ["create"], 
     "INSERT": ["INSERT","insert1"], 
     "DELETE": ["DELETE","delete1"], 
     "WITH": ["modify"]}, 
  "updateAll" : {
     "INSERT": ["?[update1,?[;,update]]"], 
     "DELETE": ["?[update1,?[;,update]]"], 
     "LOAD": ["?[update1,?[;,update]]"], 
     "CLEAR": ["?[update1,?[;,update]]"], 
     "DROP": ["?[update1,?[;,update]]"], 
     "ADD": ["?[update1,?[;,update]]"], 
     "MOVE": ["?[update1,?[;,update]]"], 
     "COPY": ["?[update1,?[;,update]]"], 
     "CREATE": ["?[update1,?[;,update]]"], 
     "WITH": ["?[update1,?[;,update]]"], 
     "$": ["?[update1,?[;,update]]"]}, 
  "usingClause" : {
     "USING": ["USING","or([iriRef,[NAMED,iriRef]])"]}, 
  "valueLogical" : {
     "!": ["relationalExpression"], 
     "+": ["relationalExpression"], 
     "-": ["relationalExpression"], 
     "VAR1": ["relationalExpression"], 
     "VAR2": ["relationalExpression"], 
     "(": ["relationalExpression"], 
     "STR": ["relationalExpression"], 
     "LANG": ["relationalExpression"], 
     "LANGMATCHES": ["relationalExpression"], 
     "DATATYPE": ["relationalExpression"], 
     "BOUND": ["relationalExpression"], 
     "IRI": ["relationalExpression"], 
     "URI": ["relationalExpression"], 
     "BNODE": ["relationalExpression"], 
     "RAND": ["relationalExpression"], 
     "ABS": ["relationalExpression"], 
     "CEIL": ["relationalExpression"], 
     "FLOOR": ["relationalExpression"], 
     "ROUND": ["relationalExpression"], 
     "CONCAT": ["relationalExpression"], 
     "STRLEN": ["relationalExpression"], 
     "UCASE": ["relationalExpression"], 
     "LCASE": ["relationalExpression"], 
     "ENCODE_FOR_URI": ["relationalExpression"], 
     "CONTAINS": ["relationalExpression"], 
     "STRSTARTS": ["relationalExpression"], 
     "STRENDS": ["relationalExpression"], 
     "STRBEFORE": ["relationalExpression"], 
     "STRAFTER": ["relationalExpression"], 
     "YEAR": ["relationalExpression"], 
     "MONTH": ["relationalExpression"], 
     "DAY": ["relationalExpression"], 
     "HOURS": ["relationalExpression"], 
     "MINUTES": ["relationalExpression"], 
     "SECONDS": ["relationalExpression"], 
     "TIMEZONE": ["relationalExpression"], 
     "TZ": ["relationalExpression"], 
     "NOW": ["relationalExpression"], 
     "UUID": ["relationalExpression"], 
     "STRUUID": ["relationalExpression"], 
     "MD5": ["relationalExpression"], 
     "SHA1": ["relationalExpression"], 
     "SHA256": ["relationalExpression"], 
     "SHA384": ["relationalExpression"], 
     "SHA512": ["relationalExpression"], 
     "COALESCE": ["relationalExpression"], 
     "IF": ["relationalExpression"], 
     "STRLANG": ["relationalExpression"], 
     "STRDT": ["relationalExpression"], 
     "SAMETERM": ["relationalExpression"], 
     "ISIRI": ["relationalExpression"], 
     "ISURI": ["relationalExpression"], 
     "ISBLANK": ["relationalExpression"], 
     "ISLITERAL": ["relationalExpression"], 
     "ISNUMERIC": ["relationalExpression"], 
     "TRUE": ["relationalExpression"], 
     "FALSE": ["relationalExpression"], 
     "COUNT": ["relationalExpression"], 
     "SUM": ["relationalExpression"], 
     "MIN": ["relationalExpression"], 
     "MAX": ["relationalExpression"], 
     "AVG": ["relationalExpression"], 
     "SAMPLE": ["relationalExpression"], 
     "GROUP_CONCAT": ["relationalExpression"], 
     "SUBSTR": ["relationalExpression"], 
     "REPLACE": ["relationalExpression"], 
     "REGEX": ["relationalExpression"], 
     "EXISTS": ["relationalExpression"], 
     "NOT": ["relationalExpression"], 
     "IRI_REF": ["relationalExpression"], 
     "STRING_LITERAL1": ["relationalExpression"], 
     "STRING_LITERAL2": ["relationalExpression"], 
     "STRING_LITERAL_LONG1": ["relationalExpression"], 
     "STRING_LITERAL_LONG2": ["relationalExpression"], 
     "INTEGER": ["relationalExpression"], 
     "DECIMAL": ["relationalExpression"], 
     "DOUBLE": ["relationalExpression"], 
     "INTEGER_POSITIVE": ["relationalExpression"], 
     "DECIMAL_POSITIVE": ["relationalExpression"], 
     "DOUBLE_POSITIVE": ["relationalExpression"], 
     "INTEGER_NEGATIVE": ["relationalExpression"], 
     "DECIMAL_NEGATIVE": ["relationalExpression"], 
     "DOUBLE_NEGATIVE": ["relationalExpression"], 
     "PNAME_LN": ["relationalExpression"], 
     "PNAME_NS": ["relationalExpression"]}, 
  "valuesClause" : {
     "VALUES": ["VALUES","dataBlock"], 
     "$": [], 
     "}": []}, 
  "var" : {
     "VAR1": ["VAR1"], 
     "VAR2": ["VAR2"]}, 
  "varOrIRIref" : {
     "VAR1": ["var"], 
     "VAR2": ["var"], 
     "IRI_REF": ["iriRef"], 
     "PNAME_LN": ["iriRef"], 
     "PNAME_NS": ["iriRef"]}, 
  "varOrTerm" : {
     "VAR1": ["var"], 
     "VAR2": ["var"], 
     "NIL": ["graphTerm"], 
     "IRI_REF": ["graphTerm"], 
     "TRUE": ["graphTerm"], 
     "FALSE": ["graphTerm"], 
     "BLANK_NODE_LABEL": ["graphTerm"], 
     "ANON": ["graphTerm"], 
     "PNAME_LN": ["graphTerm"], 
     "PNAME_NS": ["graphTerm"], 
     "STRING_LITERAL1": ["graphTerm"], 
     "STRING_LITERAL2": ["graphTerm"], 
     "STRING_LITERAL_LONG1": ["graphTerm"], 
     "STRING_LITERAL_LONG2": ["graphTerm"], 
     "INTEGER": ["graphTerm"], 
     "DECIMAL": ["graphTerm"], 
     "DOUBLE": ["graphTerm"], 
     "INTEGER_POSITIVE": ["graphTerm"], 
     "DECIMAL_POSITIVE": ["graphTerm"], 
     "DOUBLE_POSITIVE": ["graphTerm"], 
     "INTEGER_NEGATIVE": ["graphTerm"], 
     "DECIMAL_NEGATIVE": ["graphTerm"], 
     "DOUBLE_NEGATIVE": ["graphTerm"]}, 
  "verb" : {
     "VAR1": ["storeProperty","varOrIRIref"], 
     "VAR2": ["storeProperty","varOrIRIref"], 
     "IRI_REF": ["storeProperty","varOrIRIref"], 
     "PNAME_LN": ["storeProperty","varOrIRIref"], 
     "PNAME_NS": ["storeProperty","varOrIRIref"], 
     "a": ["storeProperty","a"]}, 
  "verbPath" : {
     "^": ["path"], 
     "a": ["path"], 
     "!": ["path"], 
     "(": ["path"], 
     "IRI_REF": ["path"], 
     "PNAME_LN": ["path"], 
     "PNAME_NS": ["path"]}, 
  "verbSimple" : {
     "VAR1": ["var"], 
     "VAR2": ["var"]}, 
  "whereClause" : {
     "{": ["?WHERE","groupGraphPattern"], 
     "WHERE": ["?WHERE","groupGraphPattern"]}
},

keywords:/^(GROUP_CONCAT|DATATYPE|BASE|PREFIX|SELECT|CONSTRUCT|DESCRIBE|ASK|FROM|NAMED|ORDER|BY|LIMIT|ASC|DESC|OFFSET|DISTINCT|REDUCED|WHERE|GRAPH|OPTIONAL|UNION|FILTER|GROUP|HAVING|AS|VALUES|LOAD|CLEAR|DROP|CREATE|MOVE|COPY|SILENT|INSERT|DELETE|DATA|WITH|TO|USING|NAMED|MINUS|BIND|LANGMATCHES|LANG|BOUND|SAMETERM|ISIRI|ISURI|ISBLANK|ISLITERAL|REGEX|TRUE|FALSE|UNDEF|ADD|DEFAULT|ALL|SERVICE|INTO|IN|NOT|IRI|URI|BNODE|RAND|ABS|CEIL|FLOOR|ROUND|CONCAT|STRLEN|UCASE|LCASE|ENCODE_FOR_URI|CONTAINS|STRSTARTS|STRENDS|STRBEFORE|STRAFTER|YEAR|MONTH|DAY|HOURS|MINUTES|SECONDS|TIMEZONE|TZ|NOW|UUID|STRUUID|MD5|SHA1|SHA256|SHA384|SHA512|COALESCE|IF|STRLANG|STRDT|ISNUMERIC|SUBSTR|REPLACE|EXISTS|COUNT|SUM|MIN|MAX|AVG|SAMPLE|SEPARATOR|STR)/i ,

punct:/^(\*|a|\.|\{|\}|,|\(|\)|;|\[|\]|\|\||&&|=|!=|!|<=|>=|<|>|\+|-|\/|\^\^|\?|\||\^)/ ,

startSymbol:"sparql11",
acceptEmpty:true,
}