module.exports={
  "_args": [
    [
      {
        "raw": "yasgui-utils@^1.6.0",
        "scope": null,
        "escapedName": "yasgui-utils",
        "name": "yasgui-utils",
        "rawSpec": "^1.6.0",
        "spec": ">=1.6.0 <2.0.0",
        "type": "range"
      },
      "/home/lrd900/yasgui/yasgui"
    ]
  ],
  "_from": "yasgui-utils@>=1.6.0 <2.0.0",
  "_id": "yasgui-utils@1.6.0",
  "_inCache": true,
  "_location": "/yasgui-utils",
  "_npmUser": {
    "name": "laurens.rietveld",
    "email": "laurens.rietveld@gmail.com"
  },
  "_npmVersion": "1.4.3",
  "_phantomChildren": {},
  "_requested": {
    "raw": "yasgui-utils@^1.6.0",
    "scope": null,
    "escapedName": "yasgui-utils",
    "name": "yasgui-utils",
    "rawSpec": "^1.6.0",
    "spec": ">=1.6.0 <2.0.0",
    "type": "range"
  },
  "_requiredBy": [
    "/",
    "/yasgui-yasqe",
    "/yasgui-yasr"
  ],
  "_resolved": "https://registry.npmjs.org/yasgui-utils/-/yasgui-utils-1.6.0.tgz",
  "_shasum": "bcb9091109c233e3e82737c94c202e6512389c47",
  "_shrinkwrap": null,
  "_spec": "yasgui-utils@^1.6.0",
  "_where": "/home/lrd900/yasgui/yasgui",
  "author": {
    "name": "Laurens Rietveld"
  },
  "bugs": {
    "url": "https://github.com/YASGUI/Utils/issues"
  },
  "dependencies": {
    "store": "^1.3.14"
  },
  "description": "Utils for YASGUI libs",
  "devDependencies": {},
  "directories": {},
  "dist": {
    "shasum": "bcb9091109c233e3e82737c94c202e6512389c47",
    "tarball": "https://registry.npmjs.org/yasgui-utils/-/yasgui-utils-1.6.0.tgz"
  },
  "homepage": "https://github.com/YASGUI/Utils",
  "licenses": [
    {
      "type": "MIT",
      "url": "http://yasgui.github.io/license.txt"
    }
  ],
  "main": "src/main.js",
  "maintainers": [
    {
      "name": "laurens.rietveld",
      "email": "laurens.rietveld@gmail.com"
    }
  ],
  "name": "yasgui-utils",
  "optionalDependencies": {},
  "readme": "ERROR: No README data found!",
  "repository": {
    "type": "git",
    "url": "git://github.com/YASGUI/Utils.git"
  },
  "version": "1.6.0"
}
