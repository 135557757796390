module.exports={
  "_args": [
    [
      {
        "raw": "yasgui-yasr@2.10.6",
        "scope": null,
        "escapedName": "yasgui-yasr",
        "name": "yasgui-yasr",
        "rawSpec": "2.10.6",
        "spec": "2.10.6",
        "type": "version"
      },
      "/home/lrd900/yasgui/yasgui"
    ]
  ],
  "_from": "yasgui-yasr@2.10.6",
  "_id": "yasgui-yasr@2.10.6",
  "_inCache": true,
  "_location": "/yasgui-yasr",
  "_nodeVersion": "7.4.0",
  "_npmOperationalInternal": {
    "host": "packages-18-east.internal.npmjs.com",
    "tmp": "tmp/yasgui-yasr-2.10.6.tgz_1483956109164_0.466102545382455"
  },
  "_npmUser": {
    "name": "laurens.rietveld",
    "email": "laurens.rietveld@gmail.com"
  },
  "_npmVersion": "4.0.5",
  "_phantomChildren": {},
  "_requested": {
    "raw": "yasgui-yasr@2.10.6",
    "scope": null,
    "escapedName": "yasgui-yasr",
    "name": "yasgui-yasr",
    "rawSpec": "2.10.6",
    "spec": "2.10.6",
    "type": "version"
  },
  "_requiredBy": [
    "#USER",
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/yasgui-yasr/-/yasgui-yasr-2.10.6.tgz",
  "_shasum": "f941dd60892f707db3ad569e8a0ad0534c659f6d",
  "_shrinkwrap": null,
  "_spec": "yasgui-yasr@2.10.6",
  "_where": "/home/lrd900/yasgui/yasgui",
  "author": {
    "name": "Laurens Rietveld"
  },
  "browserify": {
    "transform": [
      "browserify-shim"
    ]
  },
  "browserify-shim": {
    "google": "global:google"
  },
  "bugs": {
    "url": "https://github.com/YASGUI/YASR/issues/"
  },
  "dependencies": {
    "browserify-shim": "^3.8.12",
    "codemirror": "^5.17.0",
    "d3": "^4.1.1",
    "datatables.net": "^1.10.12",
    "datatables.net-dt": "^1.10.12",
    "jquery": "^2.2.4",
    "jquery-ui": "1.10.5",
    "leaflet": "^1.0.1",
    "lodash": "^4.16.1",
    "pivottable": "^2.1.0",
    "wicket": "git+https://github.com/arthur-e/Wicket.git",
    "yasgui-utils": "^1.6.0"
  },
  "description": "Yet Another SPARQL Resultset GUI",
  "devDependencies": {
    "bootstrap-sass": "^3.3.7",
    "browserify": "^13.1.0",
    "browserify-transform-tools": "^1.6.0",
    "exorcist": "^0.4.0",
    "gulp": "^3.9.1",
    "gulp-autoprefixer": "^3.1.0",
    "gulp-bump": "^2.2.0",
    "gulp-clean-css": "^2.0.12",
    "gulp-concat": "^2.6.0",
    "gulp-connect": "^4.2.0",
    "gulp-cssimport": "^3.1.0",
    "gulp-embedlr": "^0.5.2",
    "gulp-filter": "^4.0.0",
    "gulp-git": "^1.10.0",
    "gulp-html-replace": "^1.6.1",
    "gulp-jsvalidate": "^2.1.0",
    "gulp-livereload": "^3.8.1",
    "gulp-minify-css": "1.2.4",
    "gulp-notify": "^2.2.0",
    "gulp-rename": "^1.2.2",
    "gulp-sass": "^2.3.2",
    "gulp-sourcemaps": "^1.6.0",
    "gulp-streamify": "1.0.2",
    "gulp-tag-version": "^1.3.0",
    "gulp-uglify": "^1.5.4",
    "node-sass": "^3.8.0",
    "require-dir": "^0.3.0",
    "run-sequence": "^1.2.2",
    "vinyl-buffer": "^1.0.0",
    "vinyl-source-stream": "~1.1.0",
    "vinyl-transform": "1.0.0",
    "watchify": "^3.7.0",
    "yasgui-yasqe": "^2.10.1"
  },
  "directories": {},
  "dist": {
    "shasum": "f941dd60892f707db3ad569e8a0ad0534c659f6d",
    "tarball": "https://registry.npmjs.org/yasgui-yasr/-/yasgui-yasr-2.10.6.tgz"
  },
  "gitHead": "fb28b525207e9bfe59fdb41fbcc11659be5ac591",
  "homepage": "http://yasr.yasgui.org",
  "keywords": [
    "JavaScript",
    "SPARQL",
    "Editor",
    "Semantic Web",
    "Linked Data"
  ],
  "license": "MIT",
  "main": "src/main.js",
  "maintainers": [
    {
      "name": "laurens.rietveld",
      "email": "laurens.rietveld@gmail.com"
    }
  ],
  "name": "yasgui-yasr",
  "optionalDependencies": {},
  "optionalShim": {
    "codemirror": {
      "require": "codemirror",
      "global": "CodeMirror"
    },
    "jquery": {
      "require": "jquery",
      "global": "jQuery"
    },
    "../../lib/codemirror": {
      "require": "codemirror",
      "global": "CodeMirror"
    },
    "datatables": {
      "require": "datatables",
      "global": "jQuery"
    },
    "d3": {
      "require": "d3",
      "global": "d3"
    },
    "jquery-ui/sortable": {
      "require": "jquery-ui/sortable",
      "global": "jQuery"
    },
    "pivottable": {
      "require": "pivottable",
      "global": "jQuery"
    }
  },
  "readme": "ERROR: No README data found!",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/YASGUI/YASR.git"
  },
  "scripts": {
    "build": "gulp",
    "dev": "gulp serve",
    "major": "gulp major",
    "minor": "gulp minor",
    "patch": "gulp patch"
  },
  "version": "2.10.6"
}
